// src/components/Layout.js
import React from "react";
import PayrollHeader from "./PayrollHeader";
import PayrollFooter from "./PayrollFooter";
import { Outlet } from "react-router-dom";

const PayrollLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <PayrollHeader />
      <main className="flex-grow">
        <Outlet />
      </main>
      <PayrollFooter />
    </div>
  );
};

export default PayrollLayout;
