import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using react-router-dom for routing

function FAQ({ faqData, backgroundColor, font, text }) {
  const sectionRef = useRef(null);
  const location = useLocation(); // Get the current URL path
  const [isFixed, setIsFixed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null); // Track active index, null means none is open

  const handleScroll = () => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const sectionHeight = sectionRef.current.offsetHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // Set sticky header
      setIsFixed(sectionTop <= 100);

      // Calculate scroll position relative to the section
      const scrollPosition = Math.min(
        scrollTop - sectionRef.current.offsetTop,
        sectionHeight - window.innerHeight
      );

      const itemHeight = sectionHeight / faqData.length;

      console.log(itemHeight, "itemHeight");
      // Calculate active index based on scroll position
      const currentIndex = Math.min(
        Math.floor(scrollPosition / itemHeight),
        faqData.length - 1
      );
      setActiveIndex(currentIndex);
    }
  };

  useEffect(() => {
    if (location.pathname === "/payroll/faq") {
      sectionRef.current?.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
    handleScroll();

    const handleResize = () => {
      handleScroll();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [faqData.length, location.pathname]);

  const dynamicHeight = `${Math.min(faqData.length * window.innerHeight, 1200)}px`;

  return (
    <section
      className="py-10 px-10 bg-Yellow faq-section"
      ref={sectionRef}
      style={{ height: dynamicHeight, backgroundColor, fontFamily: font, color: text }}
    >
      <div
        className={`container m-auto xl:flex justify-between lg:px-12 ${isFixed ? "sticky-header" : ""}`}
      >
        <p className="text-[39px] lg:text-[50px] md:w-[50%] font-medium uppercase">
          Frequently
          <br />
          Asked Questions.
        </p>
        <div
          className={`w-full md:w-[50%] ${isFixed ? "faq-content-container" : ""}`}
        >
          <div className="faq-content">
            {faqData.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
                isActive={activeIndex === index} // Only show answer if activeIndex matches the current index
                index={index} // Pass index to each FAQItem
                setActiveIndex={setActiveIndex} // Pass setter to change active index
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const FAQItem = ({ question, answer, isActive, index, setActiveIndex }) => {
  const handleToggle = () => {
    // If the clicked question is already open, set activeIndex to null to close it
    setActiveIndex(isActive ? null : index);
  };

  return (
    <div className={`faq-item ${isActive ? "active" : ""}`}>
      <div
        className="faq-question text-base cursor-pointer"
        onClick={handleToggle}
      >
        <span className="text-xl">{question}</span>
        <span className="faq-toggle">{isActive ? "-" : "+"}</span>
      </div>
      {isActive && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

export default FAQ;
