// src/components/Footer.js
import React from "react";
import payrollfooter from "../images/retail-chain-header.svg";

import fb from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import x from "../images/x.svg";
import linkedin from "../images/linkdin.svg";

const RetailChainFooter = () => {
  const companyDetails = {
    title: "Company",
    address: "1234 Street Name, City, State, Zip Code",
    mobile: "(123) 456-7890",
    email: "example@example.com",
  };

  const socialLinks = [
    { src: fb, alt: "Facebook", href: "#" },
    { src: instagram, alt: "Instagram", href: "#" },
    { src: x, alt: "X", href: "#" },
    { src: linkedin, alt: "LinkedIn", href: "#" },
  ];
  const quickLinks = [
    { name: "Sign-up", href: "#" },
    { name: "Start a free trail", href: "#" },
  ];

  const payrollfootercontent =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.";

  const copyrightText = [
    "© 2021 TAZK. Copyright © 2024 TAZK. All Rights Reserved.",
  ];

  return (
    <footer className="bg-[#F5F5F5] text-white">
      <div className="container mx-auto py-8 md:py-12 px-6 md:px-8">
        <div className="grid grid-cols-1 gap-8 text-left md:grid-cols-[30%_30%_20%_20%]">
          <div>
            <img src={payrollfooter} alt="Logo" className="mx-0 mb-4" />
            <p className="text-paragraphText mb-3">{payrollfootercontent}</p>
          </div>
          <div>
            <h3 className="text-xl font-medium text-[#262626] mb-6">
              {companyDetails.title}
            </h3>
            <p className="text-paragraphText mb-3">{companyDetails.address}</p>
            <p className="text-paragraphText mb-3">
              Mobile: {companyDetails.mobile}
            </p>
            <p className="text-paragraphText mb-3">
              Email: {companyDetails.email}
            </p>
          </div>
          <div>
            <h3 className="text-xl font-medium text-[#262626] mb-6">
              Get Started Now
            </h3>
            {quickLinks.map((link, index) => (
              <p key={index} className="mb-3">
                <a href={link.href} className="text-paragraphText">
                  {link.name}
                </a>
              </p>
            ))}
          </div>
          <div className="flex flex-col">
            <h3 className="text-xl font-medium text-[#262626] mb-3">
              Follow Us
            </h3>
            <div className="flex space-x-4">
              {socialLinks.map((link, index) => (
                <a key={index} href={link.href} className="flex-shrink-0">
                  <img
                    src={link.src}
                    alt={link.alt}
                    className="w-6 h-6" // Adjust size as needed
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="px-2 md:px-8 py-4">
          <div className="container mx-auto text-center">
            <p className="text-paragraphText">
              <a
                href="/terms-and-conditions"
                className="text-paragraphText hover:text-primary underline"
              >
                Terms and Conditions
              </a>
              {" | "}
              <a
                href="/privacy-policy"
                className="text-paragraphText hover:text-primary underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#D9D9D9] px-2 md:px-8">
        <div className="text-center container mx-auto">
          <p className="text-paragraphText py-2">
            © 2021{" "}
            <a
              href="https://tazk.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-paragraphText  hover:text-primary underline"
            >
              TAZK
            </a>
            . Copyright © 2024&nbsp;
            <a
              href="https://tazk.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-paragraphText hover:text-primary underline"
            >
              TAZK
            </a>
            . All Rights Reserved. Designed by&nbsp;
            <a
              href="http://svaantech.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-paragraphText hover:text-primary underline"
            >
              SVaaN.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default RetailChainFooter;
