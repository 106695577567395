// src/pages/Home.js
// import React from "react";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import Payrollscreen1 from "../images/Payrollscreen1.json";
import Payrollscreen2 from "../images/Payrollscreen2.json";
import Payrollscreen3 from "../images/Payrollscreen3.json";
import Payrollscreen4 from "../images/Payrollscreen4.json";
import PayrollHero from "../images/Payroll-Hero.json";

// Components
import Fullwidthtitle from "../components/Herosections/Fullwidthtitle";
import Centerimgsection from "../components/Payrollsections/Centerimgsection";
import Benifits from "../components/Payrollsections/Benifits";
import Mobilebenifits from "../components/Payrollsections/Mobilebenifits";
import CTA from "../components/CTA/Cta";
import Payrollhero from "../components/Payrollsections/Payrollhero";
import TestimonialSlider from "../components/Herosections/TestimonialSlider";
import Subscription from "../components/RetailshopSections/Subscription";
import Story from "../components/RetailshopSections/Story";
import FAQ from "../components/RetailshopSections/FAQ";
import FAQmobiles from "../components/RetailshopSections/FAQmobile";

// images
import BG from "../images/cta-bg.png";
import Privacy from "../images/privacy.svg";
import benifit from "../images/benifit.svg";
import Producticon from "../images/product-icon.svg";
import ProductTour from "../images/ProductTour.svg";
import PayrollBg from "../images/payroll-bg.svg";
import CTABG from "../images/Flow1.json";
import clock from "../images/clockin.png";
import facedetection from "../images/face detection.png";
import selfie from "../images/selfie.svg";
import shiftscheduling from "../images/shift scheduling.svg";
import flexiblepolicy from "../images/flexible policy.png";
import events from "../images/events.png";
import salaryadvance from "../images/Salary advances.png";
import claims from "../images/claims.png";
import announcement from "../images/Frame 1430597.svg";
import projects from "../images/projects.jpg";
import dashboard from "../images/dashboard.svg";
import qrcode from "../images/qr-code.svg";
import splitshift from "../images/clock.svg";
import remainder from "../images/reminder.svg";
import incentive from "../images/increase.svg";
import otcalculator from "../images/calculator.svg";
import document from "../images/document.svg";
import business from "../images/business.svg";
import webdesign from "../images/web-design.svg";
import verified from "../images/verified.svg";
import retailshopchain from "../images/01Retail Chain Shop.svg";
import payrollimg from "../images/Frame 1430076.svg";
import travelhistory from "../images/travelhistory.svg";
import { SquareNextArrow, SquarePrevArrow } from "../components/RetailshopSections/CustomArrows";
import getServerConfig from "../http-common";

const Payroll = () => {
  // Responsive based component load
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [showIcons, setShowIcons] = useState(false);
  const featuresRef = useRef(null);
  const isDesktop1 = useMediaQuery({ minWidth: 1152 });

  useEffect(() => {
    // Check if the URL matches the features path
    if (window.location.pathname === "/payroll/features") {
      // Scroll to the features section with margin
      const offset = featuresRef.current.offsetTop; // Get the top offset of the section
      const marginTop = 50; // Set this to the actual margin top value in pixels

      window.scrollTo(0, offset - marginTop); // Scroll to the top of the section minus the margin
    }
  }, []);

  useEffect(() => {
    // Check if the URL matches the more-features path
    if (window.location.pathname === "/payroll/more-features") {
      // Use a timeout to ensure the DOM is fully loaded before scrolling
      setTimeout(() => {
        if (featuresRef.current) {
          // Scroll to the top of the features section
          window.scrollTo({
            top:
              featuresRef.current.getBoundingClientRect().top + window.scrollY,
            behavior: "smooth",
          });
        }
      }, 0); // Adjust the timeout if necessary
    }
  }, []);

  // Props content
  const testimonials = [
    {
      quote:
        "We used to struggle with payment delays and complicated processes, but payroll streamlined everything. Now, our international transactions are as smooth as domestic ones, and we couldn't be happier!",
      author: "Rohan M",
    },
    {
      quote:
        "Switching to payroll has been a game-changer for our business. The seamless integration and reliable transactions have made our payment process faster and more secure than ever. Our customers love the smooth checkout experience!",
      author: "Priya",
    },
    {
      quote:
        "The best part about payroll is the transparency. There are no hidden fees, and the real-time tracking of payments has given us complete control over our finances. Highly recommended!",
      author: "Keerthi Rajan",
    },
    // Add more testimonials as needed
  ];

  const tourItems = [
    {
      touricon: Producticon,
      subtitle: "Lorem Ipsum is simply dummy text of the printing.",
      tourcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trail" },
        { linkUrl: "#", text: "View more" },
      ],
    },
    // Add more items as needed
  ];

  const faqData = [
    {
      question: "01. How does free trial work?",
      answer:
        "With Tazk Payroll's free trial, you get full access to all features for 14 days, allowing you to explore the product and evaluate its benefits for your business. No credit card is needed to start the trial.",
    },
    {
      question: "02. Is there any cancellation fee?",
      answer:
        "There is no cancellation fee on any of our plans. However if you have availed an annual subscription plan the remaining credit will not be refunded if you discontinue Tazk Payroll service during the validity of the plan.",
    },
    {
      question: "03. What happens if there are changes in staff?",
      answer:
        "You can add or remove users at any time according to your needs. When you add new users, they will be charged on a pro-rata basis, meaning you'll only pay for the portion of the billing cycle during which they are active. Similarly, if you remove users, you'll receive a credit for the unused portion of their subscription. This flexible approach ensures you only pay for the user access you actually use.",
    },
    {
      question: "04. How is an active user defined?",
      answer:
        "An active user is anyone who has access to the application or holds a user account within it.",
    },
    {
      question: "05.What types of payment do you accept?",
      answer:
        "Tazk offers a range of flexible payment options, including online payments via Credit Card, Debit Card, Net Banking and UPI. For offline payment inquiries, please contact us at sales@tazk.com.",
    },
    {
      question: "06. How secure is the safety of my data?",
      answer:
        "We have implemented stringent security protocols to ensure the integrity and confidentiality of your data. Physical server access is fortified through multi-tiered access controls. Our sophisticated multi-tenancy architecture rigorously isolates customer data, preventing any possibility of cross-tenant data exposure. We enforce rigorous user authentication mechanisms and stringent password policies to safeguard your information. Furthermore, all data transmissions between your browser and our servers are secured using industry-leading 256-bit SSL encryption, providing unmatched protection",
    },
    {
      question: "07.  Who has ownership of my data?",
      answer:
        "Your data is entirely yours, with full ownership and control. You have seamless access to your data at any time with a single click. Additionally, you can export all your information at your convenience, regardless of your subscription plan.",
    },
    {
      question: "08. What happens when my free trial period ends?",
      answer:
        "When you sign up for the free trial, you'll get full access to Tazk Payroll for 14 days at no cost. To keep using the service after the trial, choose a plan and pay the subscription charges.",
    },
  ];
  const faqData1 = [
    {
      question: "01. How does free trial work?",
      answer:
        "With Tazk Payroll's free trial, you get full access to all features for 14 days, allowing you to explore the product and evaluate its benefits for your business. No credit card is needed to start the trial.",
    },
    {
      question: "02. Is there any cancellation fee?",
      answer:
        "There is no cancellation fee on any of our plans. However if you have availed an annual subscription plan the remaining credit will not be refunded if you discontinue Tazk Payroll service during the validity of the plan.",
    },
    {
      question: "03. What happens if there are changes in staff?",
      answer:
        "You can add or remove users at any time according to your needs. When you add new users, they will be charged on a pro-rata basis, meaning you'll only pay for the portion of the billing cycle during which they are active. Similarly, if you remove users, you'll receive a credit for the unused portion of their subscription. This flexible approach ensures you only pay for the user access you actually use.",
    },
    {
      question: "04. How is an active user defined?",
      answer:
        "An active user is anyone who has access to the application or holds a user account within it.",
    },
    {
      question: "05.What types of payment do you accept?",
      answer:
        "Tazk offers a range of flexible payment options, including online payments via Credit Card, Debit Card, Net Banking and UPI. For offline payment inquiries, please contact us at sales@tazk.com.",
    },
    {
      question: "06.How secure is the safety of my data?",
      answer:
        "We have implemented stringent security protocols to ensure the integrity and confidentiality of your data. Physical server access is fortified through multi-tiered access controls. Our sophisticated multi-tenancy architecture rigorously isolates customer data, preventing any possibility of cross-tenant data exposure. We enforce rigorous user authentication mechanisms and stringent password policies to safeguard your information. Furthermore, all data transmissions between your browser and our servers are secured using industry-leading 256-bit SSL encryption, providing unmatched protection",
    },
    {
      question: "07.  Who has ownership of my data?",
      answer:
        "Your data is entirely yours, with full ownership and control. You have seamless access to your data at any time with a single click. Additionally, you can export all your information at your convenience, regardless of your subscription plan.",
    },
    {
      question: "08. What happens when my free trial period ends?",
      answer:
        "When you sign up for the free trial, you'll get full access to Tazk Payroll for 14 days at no cost. To keep using the service after the trial, choose a plan and pay the subscription charges.",
    },
  ];

  const handleStartFreeTrialId4 = () => {
    const redirectUrl = getServerConfig('5', 4); 
    console.log('Opening URL:', redirectUrl);
    // window.location.href = redirectUrl; // Redirect the user
    window.open(redirectUrl, '_blank');

  };

  return (
    <>
      <Helmet>
        <title>Best Attendance & Payroll Software in India | Face ID & GPS Multi Location Attendance Software</title>
        <meta
          name="description"
          content="Simplify staff attendance and payroll with Tazk’s Payroll Management System.
           Easy-to-use solutions for Payroll, Face ID and mobile gps attendance, Live location, Daily task,
            Leave and permissions tracking, and Automatic salary calculations. Get in touch at +095000 00404"
          />
        <meta name="keywords" content="landing page, seo, example" />
        <meta property="og:title" content="Payroll | The ERP Solution" />
        <meta
          property="og:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        <meta property="og:image" content="../images/favicon.ico" />
        <meta property="og:url" content="https://tazk.in/payroll" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Payroll | The ERP Solution" />
        <meta
          name="twitter:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
      </Helmet>

      <Payrollhero
        higlighttitle="Attendance and Payroll Management System"
        payrollimg={payrollimg} // Pass the payroll image here
        animationData={PayrollHero}
        buttonText="START A FREE TRIAL"
        bgImage={PayrollBg}
        cardcontent="Effortless Attendance and Payroll Management, Anytime, Anywhere."
        onClick={handleStartFreeTrialId4}
        linkUrl="https://signup.tazk.in/signUpVerification?type=5&id=4"
     />
      <div className="bg-[rgb(230,254,240)] lg:gap-14 gap-8 md:justify-between lg:m-16 lg:p-16 mx-8 my-12 p-6 md:p-8 rounded-[50px] font-urbanist md:flex  items-center">
       <p className="md:w-[50%] text-[24px] lg:text-[50px]  md:text-[42px] md:font-bold leading-[26px] my-4 lg:leading-[68px] md:leading-[50px] font-medium md:mt-32">Streamline Attendance & Payroll with Tazk's Mobile Solution</p>
        <p className=" text-sm md:text-[16px] leading-7 lg:leading-9 md:w-[50%] lg:mx-12 lg:text-[20px] font-medium">
          Mobile Attendance and Payroll System by Tazk is a comprehensive
          solution that enhances attendance tracking and payroll management for
          businesses of all sizes. Leveraging mobile technology, it allows
          employees to clock in and out via their smartphones, making attendance
          management more flexible and convenient. The system automatically
          records work hours, reducing errors and eliminating manual entry. With
          features like real-time attendance tracking, cost efficiency,
          multi-location support, dynamic policy integration, and simplified
          payroll processes, it streamlines operations and ensures accuracy,
          offering a user-friendly experience for both employees and employers.
        </p>
      </div>

      <div
        ref={featuresRef}
        className="mt-10 md:mt-[50px] text-center font-urbanist"
      >
        <h4 className="text-black text-3xl md:text-[42px] leading-9 lg:text-5xl font-bold uppercase">
          Features
        </h4>
        <p className="xl-w-full font-urbanist lg:my-[40px] mt-[20px] md:mt-[30px] text-base lg:text-[32px] md:text-[20px] font-medium md:font-semibold text-[rgb(0,170,74)]">
          Unlock seamless efficiency with our innovative and intuitive features
        </p>
      </div>

      <div className="flex flex-col md:flex-row bg-white p-8 lg:m-12 lg:mb-0 mx-auto rounded-[30px] md:rounded-[30px] gap-2 lg:mt-4 md:gap-6">
        {/* First Column - Gray Background */}
        <div className="w-full md:w-1/3  bg-[rgb(230,254,240)] p-4 md:p-4 md:px-0 rounded-[30px] md:rounded-[30px] mb-6 md:mb-0 flex flex-col items-center gap-4 md:gap-3">
          {/* Centered Heading
          <h4 className="text-black text-center text-lg md:text-[24px] font-bold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Clock In and Clock Out from Multiple Locations
          </h4> */}

          {/* Image and Content Side by Side */}
          <div className="flex flex-col w-full items-center">
            {/* Left Side Image */}
            <div className="sm:w-full  lg:w-[90%] md:w-[90%]">
              <img
                src={clock}
                alt="Salary Advance Image"
                className="rounded-[25px] mx-auto sm:w-full md:mx-0 lg:pt-3"
              />
            </div>
            </div>
            {/* Right Side Content */}
            <div className="text-left lg:mx-2 px-4 sm:pt-3 sm:px-1">
              {/* Centered Heading */}
          <h4 className="text-black mb-2 text-lg lg:text-3xl lg:my-4 md:my-1 md:mb-3 md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[30px] w-full">
            Clock In and Clock Out from Multiple Locations
          </h4>
              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal lg:mb-6 md:leading-[24px] ">
                Tazk Payroll allows employees to log work hours from any
                location, ensuring accurate time tracking across different
                locations. It simplifies clocking in and out, enabling
                businesses with multiple branches or field-based staff to
                maintain location-specific records. Administrators can easily
                monitor attendance and hours in real-time across all locations.
              </p>
            </div>
          
        </div>
       {/* <div className="flex flex-col md:flex-row bg-white p-8 max-w-[100%] mx-auto rounded-[30px] md:rounded-[50px] gap-6"> */}
       
        {/* Second Column - Light Green Background */}
        <div className="w-full md:w-2/3  md:flex flex-col items-center gap-2 lg:gap-2 md:gap-0">
          {/* Centered Heading */}
          {/* <h4 className="text-black text-center text-lg md:text-[24px] font-bold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Face Recognition Attendance
          </h4> */}

          {/* Image and Content Side by Side */}
          <div className="flex flex-col md:flex-row bg-[rgb(240,240,240)] mb-8 md:mb-6 p-4 md:p-4  rounded-[30px] md:rounded-[30px] w-full items-center">
            {/* Left Side Image */}
            <div className="md:w-1/2 lg:w-[45%] sm:w-full">
  <img
    src={facedetection}
    alt="Announcement Image"
    className="rounded-[25px] mx-auto md:mx-0 md:w-[95%] sm:w-full lg:w-full  lg:p-3"
  />
</div>


            {/* Right Side Content */}
            <div className="md:w-1/2 text-left lg:pl-4 lg:py-4 self-baseline sm:pt-1">
            <h4 className="text-black my-2 text-lg md:text-[22px] lg:text-3xl font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Face Recognition Attendance
          </h4>
              <p className="font-urbanist lg:text-[18px] lg:leading-9 text-sm md:text-[16px] tracking-normal mb-4 md:mb-6 md:leading-[24px]">
                The face detection attendance system uses a mobile or tablet
                camera to record employee attendance. Employees scan their faces
                for check-in and check-out, ensuring quick, secure, and
                contactless timekeeping. It integrates with HR software for
                accurate attendance tracking and streamlined payroll, reducing
                touchpoints and enhancing efficiency.
              </p>
            </div>
          </div>
          <div className="w-full flex-row  flex md:flex-col items-center md:gap-0"> {/* Centered Heading */}
          {/* <h4 className="text-black text-center text-lg md:text-[24px] font-bold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Selfie Attendance
          </h4> */}

          {/* Image and Content Side by Side */}
          <div className="flex flex-col md:flex-row bg-[rgb(240,240,240)]  p-4 md:p-3 lg:pl-3 md:pl-5 rounded-[30px] md:rounded-[30px] w-full items-center">
            {/* Left Side Image */}
            <div className="md:w-[40%] lg:w-[40%] sm:w-[100%]">
            
              <img
                src={selfie}
                alt="Salary Advance Image"
                className="rounded-[25px] mx-auto md:mx-0 lg:w-full md:w-full lg:h-full md:h-60 sm:w-full  lg:p-3"
             />
            </div>

            {/* Right Side Content */}
            <div className="md:w-[55%] self-baseline lg:py-4 lg:pl-4 md:pl-4 sm:pt-1">
            <h4 className="text-black text-left py-2 lg:text-3xl text-lg md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Selfie Attendance
          </h4>

              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal md:leading-[24px] mb-4 md:mb-6">
                Employee selfie photos act as a verification tool to confirm
                their presence at the workplace. This method provides a secure
                and reliable way to confirm attendance, reducing the chances of
                proxy check-ins or fraud.
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className="flex flex-col  md:flex-row bg-white px-8 lg:mx-12 rounded-[30px] md:rounded-[30px] gap-6">
       <div className="w-full md:2/3 flex flex-col items-center gap-4 md:gap-6">
     
      {/* First Column - Gray Background */}
        

        {/* Second Column - Light Green Background */}
        <div className="flex flex-col md:flex-row bg-[rgb(240,240,240)] gap-4 object-contain p-4 md:px-4 md:py-4 rounded-[30px] md:rounded-[30px] w-full items-center">
        {/* Centered Heading */}
          

          {/* Image and Content Side by Side */}
        
            <div className="md:w-1/2 lg:w-[38%] sm:w-full">
              <img
                src={travelhistory}
                alt="Announcement Image"
                className="rounded-[25px] mx-auto md:mx-0 object-cover w-full sm:w-full lg:h-full h-52 sm:h-full md:h-56  lg:p-3"

              />
            </div>

            {/* Right Side Content */}
            <div className="md:w-1/2 lg:w-[62%] self-baseline text-left sm:pt-3">
            <h4 className="text-black lg:py-4 pb-2 lg:pb-0 md:pb-0 text-lg md:text-[22px] lg:text-3xl font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Live Tracking and Travel History
          </h4>
              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal md:leading-[24px] mb-4 md:mb-6">
                The Live Tracking and Travel History feature offers real-time
                location updates and a detailed record of past journeys. Admins
                can monitor field employees' live<br/>  locations through a
                user-friendly interface, ensuring transparency and peace of
                mind. Key features like real-time tracking, an interactive map,
                and detailed travel logs help manage teams efficiently.
              </p>
            </div>
       
        </div>
      
        <div className="w-full  flex flex-col items-center gap-4 md:gap-2">
        {/* Centered Heading */}
          

          {/* Image and Content Side by Side */}
          <div className="flex flex-col md:flex-row bg-[rgb(240,240,240)] gap-4 p-4 my-2 md:px-4 md:py-4 object-contain rounded-[30px] md:rounded-[30px] w-full items-center">
          {/* Left Side Image */}
          <div className="sm:w-full md:w-1/2 lg:w-[38%]">
  <img
    src={verified}
    alt="Announcement Image"
    className="rounded-[25px] mx-auto md:mx-0 object-cover sm:w-full sm:h-full w-full h-52 md:h-56 lg:h-full lg:p-3"
  />
</div>



            {/* Right Side Content */}
            <div className="md:w-1/2 lg:w-[62%] text-left self-baseline pr-3 sm:pt-3">
            <h4 className="text-black text-lg lg:text-3xl pb-1 lg:py-4 md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Simplified Multilevel Approval
          </h4>
              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal md:leading-[24px] mb-4 md:mb-6">
                Implement department-specific multilevel approvals for Leave,
                Permissions, Salary Advances, and Claims. Each request must be
                reviewed, approved, and verified by the relevant managers and
                department heads.
              </p>
            </div>
          </div>
        </div>
        </div>
         {/* <div className="flex flex-col md:flex-row bg-white p-6 max-w-[100%] mx-auto rounded-[30px] md:rounded-[50px] gap-6"> */}
        {/* First Column - Gray Background */}
        <div className="w-full md:w-1/3  bg-[rgb(230,254,240)] p-4 md:p-4 md:px-0 rounded-[30px] md:rounded-[30px] mb-6 md:mb-0 flex flex-col items-center gap-4 md:gap-3">
          {/* Centered Heading */}
         
          {/* Image and Content Side by Side */}
          <div className="flex flex-col w-full items-center">
             {/* Left Side Image */}
             <div className=" sm:w-full lg:w-[90%] md:w-[90%]">
             <img
                src={shiftscheduling}
                alt="Salary Advance Image"
                className="rounded-[25px] mx-auto md:mx-0 object-cover sm:w-full w-full lg:p-2 lg:h-full "

              />
            </div>

            {/* Right Side Content */}
            <div className="text-left self-baseline mt-2 md:px-4  lg:px-6">
            <h4 className="text-black lg:text-3xl lg:py-4 py-2 lg:pb-0  text-lg md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[24px] w-full">
            Dynamic Multiple Shifts Scheduling
          </h4>

              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal md:leading-[22px]">
                Create and manage work schedules with multiple shifts
                effortlessly. Our software enables flexible shift planning,
                allowing shifts to be split and assigned based on real-time
                staffing needs, employee availability, and business demands.
                Whether handling overlapping or rotating shifts, this feature
                ensures efficient coverage, avoids conflicts, and optimizes
                workforce deployment for smooth operations and maintained
                productivity.
              </p>
            </div>
          </div>
        </div>
        
        {/* Second Column - Light Green Background */}
      
      </div>

      <div className="flex flex-col md:flex-row lg:mx-12 bg-white px-8 py-4 gap-2 max-w-[100%] mx-auto rounded-[30px] md:rounded-[30px] ">
        {/* First Column - Gray Background */}
        <div className="w-full md:w-1/3  bg-[rgb(230,254,240)] p-4 md:p-4 md:px-4 rounded-[30px] md:rounded-[30px] mb-6 md:mb-0 flex flex-col items-center gap-4 md:gap-3">
        {/* Centered Heading */}
         
          {/* Image and Content Side by Side */}
          <div className="flex flex-col w-full items-center">
          {/* Left Side Image */}
            <div className="sm:w-full">
              <img
                src={flexiblepolicy}
                alt="Salary Advance Image"
                className="rounded-[25px] sm:w-full mx-auto md:mx-0 w-full lg:p-3"
              />
            </div>

            {/* Right Side Content */}
            <div className="text-left">
            <h4 className="text-black lg:text-3xl lg:py-4  py-4 md:py-2 text-lg md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[24px] w-full">
            Flexible Attendance and Leave Policy
          </h4>

              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 md:leading-[24px] tracking-normal mb-4 md:mb-6">
                Flexible attendance and leave policy to adapt to different
                employee categories, allowing for customized settings based on
                job roles, work shifts, and employment types. This approach
                enables the creation of tailored attendance rules, such as
                varying clock-in/out times, leave entitlements, and overtime
                calculations, ensuring that the attendance system aligns with
                the specific needs of each employee category.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:w-2/3 bg-white lg:gap-3 md:pl-4 max-w-[100%] mx-auto rounded-[30px] md:rounded-[30px] ">
        {/* Second Column - Light Green Background */}
        <div className="w-full mb-6 bg-[rgb(230,254,240)] md:mb-4 md:bg-[rgb(240,240,240)] p-4 md:p-4 rounded-[30px] md:rounded-[30px] flex flex-col items-center gap-4 md:gap-6">
          {/* Centered Heading */}
          

          {/* Image and Content Side by Side */}
          <div className="flex flex-col md:flex-row w-full gap-4 items-center">
            {/* Left Side Image */}
            <div className="md:w-[40%] lg:w-[40%] sm:w-full">
              <img
                src={events}
                alt="Announcement Image"
                className="rounded-[25px] mx-auto md:mx-0 lg:p-3 sm:w-full"
              />
            </div>

            {/* Right Side Content */}
            <div className="md:w-1/2 text-left self-baseline sm:pt-3">
            <h4 className="text-black lg:text-3xl lg:py-4 pb-2 md:py-0 text-lg md:text-[22px]  font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Events
          </h4>
              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal  md:leading-[24px] mb-4 md:mb-6">
                The Events Management feature allows employees to add important
                dates such as birthdays, work anniversaries, and other
                significant life events. These events are visible to all
                employees on their dashboards, enabling them to send well wishes
                and celebrate these occasions together.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full bg-[rgb(240,240,240)] p-4 md:p-4 rounded-[30px] md:rounded-[30px] flex flex-row items-center gap-4 md:gap-6">
          {/* Centered Heading */}
         
          {/* Image and Content Side by Side */}
          <div className="flex flex-col md:flex-row w-full gap-4 items-center">
            {/* Left Side Image */}
            <div className="md:w-[45%] lg:w-[40%] sm:w-full">
              <img
                src={announcement}
                alt="Announcement Image"
                className="rounded-[25px] mx-auto md:mx-0 sm:w-full lg:p-3"
              />
            </div>

            {/* Right Side Content */}
            <div className="md:w-1/2  text-left self-baseline sm:pt-2">
            <h4 className="text-black lg:text-3xl lg:py-4 md:py-0 py-1 text-lg md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Announcements
          </h4>

              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal md:leading-[24px] mb-4 md:mb-4">
                The Announcements feature enables you to quickly send messages
                to all employees with a single click. Announcements can be
                targeted to all employees, specific departments, or individual
                employees. You can send multiple announcements and set an
                expiration date for each, ensuring that information is
                communicated effectively and remains relevant.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className=" md:flex-row bg-white p-6 max-w-[100%] mx-2 rounded-[30px] md:rounded-[50px] gap-6 lg:mx-12">
        {/* First Column - Gray Background */}
        <div className="w-full  bg-[rgb(240,240,240)] p-4 md:p-4 rounded-[30px] md:rounded-[30px] mb-6 md:mb-0 flex flex-col items-center gap-4 md:gap-6">
          {/* Centered Heading */}
          

          {/* Image and Content Side by Side */}
          <div className="flex flex-col md:flex-row w-full gap-4 items-center">
            {/* Left Side Image */}
            <div className="md:w-1/3 sm:w-full">
              <img
                src={salaryadvance}
                alt="Salary Advance Image"
                className="rounded-[25px] sm:w-full mx-auto md:mx-0 object-cover w-full  md:h-60 lg:h-full lg:p-3"
                />
            </div>

            {/* Right Side Content */}
            <div className="md:w-2/3 text-left self-baseline sm:pt-3">
            <h4 className="text-black lg:text-3xl lg:py-4 pb-2 md:pb-0 lg:pb-0 text-lg md:text-[22px] font-semibold font-urbanist leading-[24px] md:leading-[44px] w-full">
            Salary Advance Management
          </h4>
              <p className="font-urbanist text-sm md:text-[16px] lg:text-[18px] lg:leading-9 tracking-normal md:leading-[24px] mb-4 md:mb-6">
                The salary advance management feature allows employees to
                request salary advances directly through their login, specifying
                the repayment method and number of instalments. Once approved,
                the loan amount is automatically deducted from the employee's
                salary according to the agreed terms. Employees can view their
                loan statements at any time, streamlining the process and
                reducing the need for manual interventions and complex
                accounting maintenance for the employer.
              </p>
            </div>
          </div>
        </div>

        {/* Second Column - Light Green Background */}
      
      </div>

      {isDesktop ? (
        <Benifits
          title=" You Deserve the Best – Enjoy Your Amazing Free Add-Ons!"
          subtitle=" Projects & Time Tracking – Kanban Board "
          content="Our GPS-enabled project management with time tracking streamlines task coordination for both field and in-house teams, ideal for sales and service industries. Users can create, schedule, and assign tasks with time estimates and deadlines, offering real-time task monitoring and reporting."
          benifit={projects}
          cardtitle1="Single Location Projects"
          cardcontent1=" Tasks are geofenced, ensuring field executives can only start and complete tasks within specific coordinates."
          cardtitle2="Multi-Location Projects"
          cardcontent2=" GPS-embedded tasks ensure field executives complete work at designated multiple locations."
          cardtitle3="Live Location Projects"
          cardcontent3=" Real-time tracking of field executives ensures tasks are performed at the correct location."
          cardtitle4="Regular Projects"
          cardcontent4="Non-GPS tasks with integrated time tracking to monitor time spent and generate detailed reports."
          cardtitle5="Chat"
          cardcontent5="Chat feature by Tazk enables seamless communication among all employees within the organization. It supports document sharing, allowingteam members to exchange files and collaborate efficiently in real time."
          subtitle1="Chat"
          content1="Chat feature by Tazk enables seamless communication among all employees within the organization. It supports document sharing, allowing team members to exchange files and collaborate efficiently in real time."
        />
      ) : (
        <Mobilebenifits
          title=" You Deserve the Best – Enjoy Your Amazing Free Add-Ons!"
          subtitle=" Projects & Time Tracking – Kanban Board "
          content="Our GPS-enabled project management with time tracking streamlines task coordination for both field and in-house teams, ideal for sales and service industries. Users can create, schedule, and assign tasks with time estimates and deadlines, offering real-time task monitoring and reporting."
          benifit={projects}
          cardtitle1="Single Location Projects"
          cardcontent1=" Tasks are geofenced, ensuring field executives can only start and complete tasks within specific coordinates."
          cardtitle2="Multi-Location Projects"
          cardcontent2=" GPS-embedded tasks ensure field executives complete work at designated multiple locations."
          cardtitle3="Live Location Projects"
          cardcontent3=" Real-time tracking of field executives ensures tasks are performed at the correct location."
          cardtitle4="Regular Projects"
          cardcontent4="Non-GPS tasks with integrated time tracking to monitor time spent and generate detailed reports."
          cardtitle5="Chat"
          cardcontent5="Chat feature by Tazk enables seamless communication among all employees within the organization. It supports document sharing, allowingteam members to exchange files and collaborate efficiently in real time."
          subtitle1="Chat"
          content1="Chat feature by Tazk enables seamless communication among all employees within the organization. It supports document sharing, allowing team members to exchange files and collaborate efficiently in real time."
        />
      )}

      <div
        ref={featuresRef}
        className="bg-white p-6 md:px-10 text-black"
      >
        <div className="text-center md:mb-8 font-urbanist">
          <p className="mt-4 mb-4 text-xl md:text-4xl lg:text-[37px] font-semibold uppercase whitespace-normal">
            Discover even more features with our application!
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 md:mt-14 md:mx-48 font-urbanist">
          {/* First Row */}
          <div className="flex flex-col lg:py-16 bg-[rgb(230,254,240)] rounded-[25px] py-8 px-4 items-center text-center ">
            <img
              src={dashboard}
              alt="Icon 1"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm   md:text-lg">Web Dashboard</p>
          </div>
          <div className="flex flex-col lg:py-16 items-center bg-[rgb(240,240,240)] rounded-[25px] py-4 px-4 text-center">
            <img
              src={qrcode}
              alt="Icon 2"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">Wifi and QR <br/>Attendance</p>
          </div>
          <div className="flex flex-col lg:py-16 bg-[rgb(230,254,240)] rounded-[25px] py-8 px-4 items-center text-center">
            <img
              src={splitshift}
              alt="Icon 3"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">Split shift</p>
          </div>

          {/* Second Row */}
          <div className="flex flex-col lg:py-16 bg-[rgb(240,240,240)] rounded-[25px] py-4 px-4 items-center text-center">
            <img
              src={remainder}
              alt="Icon 4"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">Attendance<br/> Reminders</p>
          </div>
          <div className="flex flex-col lg:py-16 bg-[rgb(230,254,240)] rounded-[25px] py-8 px-4 items-center text-center">
            <img
              src={incentive}
              alt="Icon 5"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">Incentive upload</p>
          </div>
          <div className="flex flex-col lg:py-16 bg-[rgb(240,240,240)] rounded-[25px] py-8 px-4 items-center text-center">
            <img
              src={otcalculator}
              alt="Icon 6"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">OT Calculation</p>
          </div>

          {/* Third Row */}
          <div className="flex flex-col lg:py-16 bg-[rgb(230,254,240)] rounded-[25px] py-4 px-4 items-center text-center">
            <img
              src={document}
              alt="Icon 7"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">
              Employee Document<br/> Management
            </p>
          </div>
          <div className="flex flex-col lg:py-16 bg-[rgb(240,240,240)] rounded-[25px] py-4 px-4 items-center text-center">
            <img
              src={business}
              alt="Icon 8"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">
              Report for Bank Bulk <br/>Salary Upload
            </p>
          </div>
          <div className="flex flex-col lg:py-16 bg-[rgb(230,254,240)] rounded-[25px] py-8 px-4 items-center text-center">
            <img
              src={webdesign}
              alt="Icon 9"
              className="w-8 h-8 md:w-16 md:h-16" // Reduced size
            />
            <p className="mt-4 text-sm md:text-lg">Custom Theme</p>
          </div>
        </div>
      </div>

      <Story title="Tour Title" items={tourItems} />

      <Fullwidthtitle
        title="Your"
        spantitle="Privacy"
        PrivacyImg={Privacy}
        title2="Is Our 
        Responsibility"
        paragraph="Your privacy is our top priority. We are committed to safeguarding your personal information with the highest level of security and transparency, ensuring your data remains safe and confidential."
      />

      <Subscription />
      <div className="relative">
        {/* Floating Circle Chat Icon */}
        <div className="fixed bottom-5 right-5 z-50 flex flex-col items-center">
          {" "}
          {/* Centered the icons */}
          {/* Display Icons above the circle icon when clicked */}
          {showIcons && (
            <div className="flex flex-col items-center mb-2 space-y-2">
              {/* WhatsApp Icon */}
              <div className="relative group flex flex-col items-center">
                <a
                  href="https://wa.me/9500000404"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "#25D366" }} // WhatsApp green background
                  >
                    <i className="fab fa-whatsapp text-white text-2xl"></i>{" "}
                    {/* White WhatsApp icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  WhatsApp
                </span>
              </div>

              {/* Phone Icon */}
              <div className="relative group flex flex-col items-center">
                <a href="tel:+919500000404" className="flex items-center">
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "#25D366" }} // Phone green background
                  >
                    <i className="fas fa-phone text-white text-2xl"></i>{" "}
                    {/* White Phone icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  Call Us
                </span>
              </div>

              {/* Email Icon */}
              <div className="relative group flex flex-col items-center">
                <a href="mailto:mail@tazk.in" className="flex items-center">
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "rgb(0, 123, 255)" }} // Blue background for Email
                  >
                    <i className="fas fa-envelope text-white text-2xl"></i>{" "}
                    {/* White Email icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  Email Us
                </span>
              </div>
            </div>
          )}
          {/* Main Circle Chat Icon */}
          <div
            onClick={() => setShowIcons(!showIcons)} // Toggle icon visibility
            className="w-[54px] h-[54px] rounded-full flex items-center justify-center shadow-lg cursor-pointer"
            style={{ backgroundColor: "rgb(0, 170, 74)" }} // Green background for the chat icon
          >
            {showIcons ? (
              <i className="fas fa-times text-white text-xl"></i> // Close icon when icons are visible
            ) : (
              <i className="fas fa-comments text-white text-xl"></i> // Chat icon when icons are hidden
            )}
          </div>
        </div>
      </div>

      {isDesktop1 ? (
        <FAQ faqData={faqData} 
        font="urbanist" 
        backgroundColor= "#FFFBE6"
        text="#000000"
        border="#000000"/>
      ) : (
        <FAQmobiles faqData={faqData1}
        font="urbanist" 
        backgroundColor= "#FFFBE6"
        text="#000000"
        border="#000000"  />
      )}
 
      <TestimonialSlider
        testimonials={testimonials}
        slidesToShowDesktop={1}
        slidesToShowMobile={1}
        backgroundColor="#E6FEF0"
        customNextArrow={<SquareNextArrow />}  // Passing circle arrow
        customPrevArrow={<SquarePrevArrow />}
      />
     
    </>
  );
};

export default Payroll;
