import React from 'react'
import CTA from "../components/CTA/Cta";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import Leadshead from "../components/LeadsSections/Leadhead"
const Leads = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <>
    <Helmet>
    <title>PointOfSale | The ERP Solution</title>
    <meta
      name="description"
      content="This is the SEO description for Tazk."
    />
    <meta name="keywords" content="landing page, seo, example" />
    <meta property="og:title" content="RetailShop | The ERP Solution" />
    <meta
      property="og:description"
      content="Learn more about Tazk, the ultimate ERP solution for businesses."
    />
    <meta property="og:image" content="../images/favicon.ico" />
    <meta property="og:url" content="https://tazk.in/RetailShop" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="RetailShop | The ERP Solution" />
    <meta
      name="twitter:description"
      content="Learn more about Tazk, the ultimate ERP solution for businesses."
    />
  </Helmet>
  
   <Leadshead />
  </>
  )
}

export default Leads
