import React from 'react'
import RetailBg from "../../images/RetailBg.svg";
import received from "../../images/Received.svg"
import RetailBgMobile from "../../images/RetailBgImgMobile.svg"

function Image ({cardcontent,cardcontent2,cardcontent3, cardtitle}) {
  return (
   <section className='py-10  px-6 md:px-0 lg:mt-0 bg-black whychooseDesktop '>
     <div className="flex md:flex-row flex-col justify-between  md:px-10 font-onest md:gap-28">
          <p className="text-white font-bold md:font-normal text-2xl md:text-5xl md:w-1/2 md:leading-[60px]">{cardtitle}</p>
          <div className="text-gray text-sm md:text-base md:w-1/2 leading-6 md:leading-7 py-4 md:pr-8">
          <p style={{textIndent:'0rem'}}>{cardcontent}</p>
          <p style={{textIndent:'0rem'}}>{cardcontent2}</p>
          <p style={{textIndent:'0rem'}}> {cardcontent3}</p>
          </div>
        </div>
        <div className="md:m-10 justify-center flex flex-col border border-gray rounded-[30px] text-gray">
          {/* <div className="flex flex-row p-4 text-white justify-between">
            <p className="text-2xl flex flex-row">
              <img src={received}/>
              Lorem Ipsum</p>
            <p>Lorem</p>
          </div> */}
          <img src={RetailBg} className='hidden md:block'/>
          <img src={RetailBgMobile} className='block md:hidden'/>
        </div>
   </section>
  )
}

export default Image
