import React, { useEffect, useRef, useState } from "react";
import Tick from "../../images/tick-icon.svg";
import { useLocation } from "react-router-dom";
import getServerConfig from "../../http-common";
import axios from 'axios';
const Table = () => {
  const [selectedPlan, setSelectedPlan] = useState("STARTER"); // State to store selected plan
  const featuresRef = useRef(null);
  const location = useLocation();
  const [responseData, setResponseData] = useState([])

  const host = getServerConfig('getHost', 1)


  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value); // Update the selected plan
  };
  useEffect(() => {
    if (location.pathname === "/pointofsale/table") {
      // Scroll into view at the top of the section
      featuresRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [location]);
  useEffect(() => {
    // Check if the device is an Android device
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      // Add a class to the <body> element for Android browsers
      document.body.classList.add("android-browser");
    }
  }, []); 
  useEffect(() => {
    axios.get(`${host}/subscriptionPlans/getSubscriptionPlans`, {
      headers: {
        'Content-type': 'application/json',
      },
    })
    .then((res) => {
      setResponseData(res.data)
      console.log(res.data)
    })
    .catch((err) => console.log(err))
  }, [])

  return (
    <div>
   <div id="pricing-section " ref={featuresRef}>
    <div className='tableDesktop md:block hidden bg-black p-15 font-onest'>
      <section className='container m-auto'>  
        <div>
          <table className='border-separate w-full text-left border-spacing-0' style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
            <thead className='text-white'>
              <tr>
                <th className='border-white border-[1px] rounded-tl-[20px] items-center  p-8'>
                    <p className='font-poppins text-[22px] text-drkGray font-medium'>Compare <br/> plans</p>
                    <p className=' text-[16px] text-drkgray font-normal mt-2'>Choose your <br/>
                         workspace plan <br/>
                          according to your <br/>
                           organisational plan</p>
                           </th>  
                <th className='border-white border-y-[1px] p-2  text-center pt-8'>
                    <p className=' text-[39px] font-bold'>Free
                    
                    <span className='text-[14px] font-normal'> /Lifetime</span>
                    </p>
                    <p className=' text-[18px] font-medium text-drkgray'> 1 User</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=1",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Register Now
                    </button>
                    </th>  
                <th className='border-white border-[1px]  text-center p-2'>
                <s className="text-gray text-[18px] font-medium mr-2">
                        6000₹
                      </s>{" "}
                      {/* Strike-through original price */}
                <p className=' text-[39px] font-bold'>3600₹
                    
                    <span className='text-[14px] font-normal'> /Year</span>
                    </p>
                    <p className=' text-[18px] font-medium text-drkgray'>Up to 10 Users</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Start Your Free Trial
                    </button>
                    </th>  
                    <th className='border-white border-y-[1px]  text-center p-2'>
                <s className="text-gray text-[18px] font-medium mr-2">
                        24000₹
                      </s>{" "}
                      {/* Strike-through original price */}
                <p className=' text-[39px] font-bold'>14400₹
                    
                    <span className='text-[14px] font-normal'> /Year</span>
                    </p>
                    <p className=' text-[18px] font-medium text-drkgray'>Up to 50 Users</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Start Your Free Trial
                    </button>
                    </th>  
                    <th className='border-white border-[1px]  text-center p-2 pt-8 rounded-tr-[20px]'>
                {/* <s className="text-gray text-[18px] font-medium mr-2">
                        3000₹
                      </s>{" "} */}
                      {/* Strike-through original price */}
                {/* <p className=' text-[39px] font-bold'>2600₹
                    
                    <span className='text-[14px] font-normal'> /Year</span>
                    </p> */}
                    <p className=' text-[30px] font-bold'>Contact Us</p>
                    <p className=' text-[18px] font-medium text-drkgray'>Unlimited Users</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Start Your Free Trial
                    </button>
                    </th>  
              </tr>
            </thead>
            <tbody className='text-white text-center'>
              <tr className='bg-drkYellow text-black text-[18px] font-medium font-poppins'>
                <td className='border-drkYellow border-x-[1px] px-4 py-8'>Discount</td>  
                <td className='border-drkYellow px-4 py-8'></td>  
                <td className='border-drkYellow border-x-[1px] px-4 py-8'>40% Off</td>  
                <td className='border-drkYellow px-4 py-8'>40% Off</td>  
                <td className='border-drkYellow border-x-[1px] px-4 py-8'></td>  
              </tr>
              <tr>
                <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Web Portal Access</p>
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Mobile & Tap applications</p>
                    </td>  
                <td className='border-white  px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-x-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white  px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Sync With GrowRetail and LocSto </p>
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
              <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Multi Locations (Branches)</p>
                    </td>   
                <td className='border-white px-4 py-2'>1 Branch</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>3 Branch</td>  
                <td className='border-white px-4 py-2'>10 Branches</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>Unlimited</td>  
              </tr>
              <tr>
              <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Multiple POS Counters</p>
                    </td>   
                <td className='border-white border-y-[1px] px-4 py-2'>1 Counter</td>  
                <td className='border-white border-[1px] px-4 py-2'>6 Counters</td>  
                <td className='border-white border-y-[1px] px-4 py-2'>30 Counters</td>  
                <td className='border-white border-[1px] px-4 py-2'>Unlimited</td>  
              </tr>
              <tr>
              <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Inventories</p>
                    </td> 
                <td className='border-white px-4 py-2'>200 Products</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>2000 Products</td>  
                <td className='border-white px-4 py-2'>5000 Products</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>Unlimited</td>  
              </tr>
              <tr>
              <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Sales & purchase Orders</p>
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
              </tr><tr>
              <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Repair & Service Module</p>
                    </td> 
                <td className='border-white px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-x-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-x-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
              </tr>
              <tr>
              <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Simple Expenses Entry</p>
                    </td> 
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Set Approvals for Transactions</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Customer & Vendor Price List</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-drkYellow bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black  text-[18px] font-medium'>Custom Alerts & Reminders</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Comprehensive Calander</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Easy Debit and Credit Notes</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Easy Physical Stock Check</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Accounts</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Cash and Bank</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Easy Bank Reconciliation</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Deleted Logs</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-black bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black text-[18px] font-medium'>GST Compliance</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-drkYellow bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black text-[18px] font-medium'>Schemes Tracker & Dashboard</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Loan Accounts</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-drkYellow bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black text-[18px] font-medium'>Payment Scheduler</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Custom Reports</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Simple Stock Transfers</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Asset Management</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               Addon
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Leads Management</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                Addon
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 rounded-bl-[20px]'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Payroll Management</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                Addon
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 rounded-br-[20px]'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              
            </tbody>
          </table>
        </div>
      </section>
    </div>
    </div>
    <section className="mt-10 px-4 py-6 max-w-full overflow-auto bg-black tableMobile object-cover text-center whitespace-nowrap">
    
    {/* <section className='container m-auto'>   */}
        <div>
          <table className='border-separate w-full text-left border-spacing-0' style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
            <thead className='text-white'>
              <tr>
                <th className='border-white border-[1px] rounded-tl-[20px] items-center  p-8'>
                    <p className='font-poppins text-[22px] text-drkGray font-medium'>Compare <br/> plans</p>
                    <p className=' text-[16px] text-drkgray font-normal mt-2'>Choose your <br/>
                         workspace plan <br/>
                          according to your <br/>
                           organisational plan</p>
                           </th>  
                <th className='border-white border-y-[1px] p-2  text-center pt-8'>
                    <p className=' text-[39px] font-bold'>Free
                    
                    <span className='text-[14px] font-normal'> /Lifetime</span>
                    </p>
                    <p className=' text-[18px] font-medium text-drkgray'> 1 User</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=1",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Register Now
                    </button>
                    </th>  
                <th className='border-white border-[1px]  text-center p-2'>
                <s className="text-gray text-[18px] font-medium mr-2">
                        6000₹
                      </s>{" "}
                      {/* Strike-through original price */}
                <p className=' text-[39px] font-bold'>3600₹
                    
                    <span className='text-[14px] font-normal'> /Year</span>
                    </p>
                    <p className=' text-[18px] font-medium text-drkgray'>Up to 10 Users</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Start Your Free Trial
                    </button>
                    </th>  
                    <th className='border-white border-y-[1px]  text-center p-2'>
                <s className="text-gray text-[18px] font-medium mr-2">
                        24000₹
                      </s>{" "}
                      {/* Strike-through original price */}
                <p className=' text-[39px] font-bold'>14400₹
                    
                    <span className='text-[14px] font-normal'> /Year</span>
                    </p>
                    <p className=' text-[18px] font-medium text-drkgray'>Up to 50 Users</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Start Your Free Trial
                    </button>
                    </th>  
                    <th className='border-white border-[1px]  text-center p-2 pt-8 rounded-tr-[20px]'>
                {/* <s className="text-gray text-[18px] font-medium mr-2">
                        3000₹
                      </s>{" "} */}
                      {/* Strike-through original price */}
                {/* <p className=' text-[39px] font-bold'>2600₹
                    
                    <span className='text-[14px] font-normal'> /Year</span>
                    </p> */}
                    <p className=' text-[30px] font-bold'>Contact Us</p>
                    <p className=' text-[18px] font-medium text-drkgray'>Unlimited Users</p>
                    <button
                      onClick={() =>
                        window.open(
                          "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
                          "_blank"
                        )
                      }
                      className="text-[16px] font-medium rounded-[14px] border-white border-[0.5px] text-center m-4 p-2"
                    >
                      Start Your Free Trial
                    </button>
                    </th>  
              </tr>
            </thead>
            <tbody className='text-white text-center'>
              <tr className='bg-drkYellow text-black text-[18px] font-medium font-poppins'>
                <td className='border-drkYellow border-x-[1px] px-4 py-8'>Discount</td>  
                <td className='border-drkYellow px-4 py-8'></td>  
                <td className='border-drkYellow border-x-[1px] px-4 py-8'>40% Off</td>  
                <td className='border-drkYellow px-4 py-8'>40% Off</td>  
                <td className='border-drkYellow border-x-[1px] px-4 py-8'></td>  
              </tr>
              <tr>
                <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Web Portal Access</p>
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Mobile & Tap applications</p>
                    </td>  
                <td className='border-white  px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-x-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white  px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Sync With GrowRetail and LocSto </p>
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
              <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Multi Locations (Branches)</p>
                    </td>   
                <td className='border-white px-4 py-2'>1 Branch</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>3 Branch</td>  
                <td className='border-white px-4 py-2'>10 Branches</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>Unlimited</td>  
              </tr>
              <tr>
              <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Multiple POS Counters</p>
                    </td>   
                <td className='border-white border-y-[1px] px-4 py-2'>1 Counter</td>  
                <td className='border-white border-[1px] px-4 py-2'>6 Counters</td>  
                <td className='border-white border-y-[1px] px-4 py-2'>30 Counters</td>  
                <td className='border-white border-[1px] px-4 py-2'>Unlimited</td>  
              </tr>
              <tr>
              <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Inventories</p>
                    </td> 
                <td className='border-white px-4 py-2'>200 Products</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>2000 Products</td>  
                <td className='border-white px-4 py-2'>5000 Products</td>  
                <td className='border-white border-x-[1px] px-4 py-2'>Unlimited</td>  
              </tr>
              <tr>
              <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Sales & purchase Orders</p>
                    </td>  
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
              </tr><tr>
              <td className='border-white border-x-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Repair & Service Module</p>
                    </td> 
                <td className='border-white px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-x-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-x-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
              </tr>
              <tr>
              <td className='border-white border-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Simple Expenses Entry</p>
                    </td> 
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-y-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
                <td className='border-white border-[1px] px-4 py-2'>
                <img src={Tick} alt="" className="mx-auto" /></td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Set Approvals for Transactions</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Customer & Vendor Price List</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-drkYellow bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black  text-[18px] font-medium'>Custom Alerts & Reminders</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Comprehensive Calander</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Easy Debit and Credit Notes</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Easy Physical Stock Check</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Accounts</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Cash and Bank</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Easy Bank Reconciliation</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Deleted Logs</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-black bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black text-[18px] font-medium'>GST Compliance</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-drkYellow bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black text-[18px] font-medium'>Schemes Tracker & Dashboard</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Loan Accounts</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-drkYellow bg-drkYellow border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-black text-[18px] font-medium'>Payment Scheduler</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Custom Reports</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Simple Stock Transfers</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Asset Management</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
               Addon
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                    <p className='text-drkgray1 text-[18px] font-medium'>Leads Management</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                Addon
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 '>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              <tr>
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 rounded-bl-[20px]'>
                    <p className='text-drkgray1 text-[18px] font-medium'>Payroll Management</p>
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8'>
                -
                    </td>  
                <td className='border-white border-b-[1px] px-4 py-8'>
                Addon
                </td>  
                <td className='border-white border-x-[1px] border-b-[1px] px-4 py-8 rounded-br-[20px]'>
                <img src={Tick} alt="" className="mx-auto" />
                </td>  
              </tr>
              
            </tbody>
          </table>
        </div>
      </section>
    </div>
  )
}

export default Table
