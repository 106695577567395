import React, { useState } from 'react';
import animation2 from "../../images/Animation2.svg";
import purple from "../../images/Star purple.svg";
import yellow from "../../images/Star yellow.svg";
import green from "../../images/Star green.svg";
import red from "../../images/Star red.svg";
import Approvals from "../../images/Approval.svg";
import Quotations from "../../images/Quotation.svg";
import BranchStockTransfer from "../../images/Stock transfer.svg";
import PhysicalStockCheck from "../../images/Daily check.svg";
import Payments from "../../images/Payment.svg";
import EasyReturns from "../../images/Eazy return.svg";
import bg from "../../images/vivid-bg.svg"
import Approvalsmob from "../../images/Approval mob.svg"
import Quotationsmob from "../../images/Quotation mob.svg"
import BranchStockTransfermob from "../../images/Stock transfer mob.svg"
import PhysicalStockCheckmob from "../../images/Daily check mob.svg"
import Paymentsmob from "../../images/Payment mob.svg"
import EasyReturnsmob from "../../images/Eazy return mob.svg"
function Animationgrid({ title, subtitle, backgroundColor, header1, header2, header3, header4, header5, header6 }) {
  const initialHeaders = [
    { label: header1, icon: purple },
    { label: header2, icon: yellow },
    { label: header3, icon: green },
    { label: header4, icon: red },
    { label: header5, icon: red },
    { label: header6, icon: red },
  ];

  const [headers, setHeaders] = useState(initialHeaders);
  const [selectedHeader, setSelectedHeader] = useState(headers[0].label); // Default to header1 content

  // Content based on selected header
  const contentMap = {
    [header1]: {
      title: "Approvals",
      subtitle: "Simplified Setup for Transaction Approvals",
      image: Approvals,
      imagemob: Approvalsmob,
    },
    [header2]: {
      title: "Quotations",
      subtitle: "Send Customer Quotes is simple.",
      image: Quotations,
      imagemob: Quotationsmob,
    },
    [header3]: {
      title: "Branch Stock Transfer",
      subtitle: "Effortlessly Transfer and Receive Stock Using Our Mobile App",
      image: BranchStockTransfer,
      imagemob: BranchStockTransfermob,
    },
    [header4]: {
      title: "Physical Stock Check",
      subtitle: "Effortless physical stock check every single day.",
      image: PhysicalStockCheck,
      imagemob: PhysicalStockCheckmob,
    },
    [header5]: {
      title: "Payments",
      subtitle: "Simple Design for Pay In-Out Payment Entries.",
      image: Payments,
      imagemob: Paymentsmob,
    },
    [header6]: {
      title: "Easy Returns",
      subtitle: "Generate Stock Returns Directly from Inventory",
      image: EasyReturns,
      imagemob: EasyReturnsmob,
    },
  };

  const { title: contentTitle, subtitle: contentSubtitle, image: contentImage,imagemob:contentImagemob } = contentMap[selectedHeader] || {};

  // Handle header click to reorder and set content
  const handleHeaderClick = (clickedHeader) => {
    setHeaders((prevHeaders) => {
      const newHeaders = prevHeaders.filter(h => h.label !== clickedHeader.label);
      newHeaders.push(clickedHeader); // Move clicked header to end
      return newHeaders;
    });
    setSelectedHeader(clickedHeader.label);
  };

  return (
    <section className="font-onest bg-black pb-20">
      <div className="pt-28 px-6 md:px-10 pb-10" style={{ backgroundColor }}>
        {/* <p className="text-center pb-10 text-white text-4xl font-medium">{title}</p> */}

        {/* Header List */}
        <div>
          {headers.map((header, index) => (
            <div
              key={index}
              className={`cursor-pointer border-gray border-t-[1px] border-x-[1px] py-2 md:py-3 text-white text-[14px] md:text-[22px] rounded-t-[20px] flex flex-row md:gap-4 ${
                index === 0 ? 'mx-20 md:mx-40' : index === 1 ? 'mx-16 md:mx-32' : index === 2 ? 'mx-12 md:mx-24' : index === 3 ? 'mx-8 md:mx-16' : index === 4 ? 'mx-4 md:mx-8' : 'mx-0'
              }`}
              onClick={() => handleHeaderClick(header)}
            >
              <div
                className={`${
                  index === headers.length - 1 ? '' : 'transform hover:-translate-y-2 hover:py-3 hover:border-b-[1px]'
                } transition duration-500 w-full flex justify-start items-center gap-3`}
              >
                <img src={header.icon} className="px-2" alt={`${header.label} icon`} />
                {header.label}
              </div>
            </div>
          ))}
        </div>

        {/* Content Display */}
        <div className="md:flex justify-between border-gray rounded-b-[20px] px-4 md:px-0 md:pl-12 md:pb-0 pb-4 md:py-12 border-[0.5px] text-gray">
          <div className="md:w-1/2">
            <p className="text-white md:text-5xl text-2xl font-medium md:font-normal leading-[40px] md:leading-[60px]">{contentTitle}</p>
            <p className="text-gray md:text-[18px] text-[14px] py-2 w-[90%] md:py-4">{contentSubtitle}</p>
          </div>
          <div className="pr-0 hidden md:block">
            {contentImage && <img src={contentImage} alt={contentTitle} />}
          </div>
          <div className="pr-0 block md:hidden">
            {contentImagemob && <img src={contentImagemob} alt={contentTitle} />}
          </div>
        </div>
      </div>
      {/* <div className='flex flex-row w-[90%] justify-center items-center mx-auto relative'> */}
        
        {/* Content Section with Border */}
       
        {/* <div className='flex flex-col justify-center items-start z-10 text-white'>
          <div className='relative text-left items-center w-[80%] p-16 pl-20 pr-0 overflow-hidden my-20 rounded-bl-[170px] rounded-tl-[170px] border-[1px] border-r-0 border-gray'>
          <div className='w-[68%] leading-[20px]'>
            <p className='text-[39px] font-medium pb-2'>Free add-on</p>
            <p className='text-drkYellow py-4 text-[20px] font-normal'>Repair Service Module</p>
            <p className='text-[15px] font-light'>
              Efficiently manage walk-in customers for repair services with our simple and effective repair service add-on. Effortlessly create job sheets, assign tasks to technicians, allocate spare parts from inventory, and oversee the entire repair process through to completion—all within an intuitive and efficient interface.
            </p>
            </div>
          </div>
        </div> */}
        
        {/* Enlarged Image Element positioned to touch the bordered section */}
        {/* <div className='absolute right-[0%] top-[-10%] z-10 overflow-hidden'>
          <img 
            src={bg} 
            className='w-[100%]  h-auto object-cover transform  z-20' 
            alt="Overlay" 
          />
        </div>
      </div> */}
    </section>
  );
}

export default Animationgrid;
