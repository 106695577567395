import React, { useState } from "react";
import arrowdown from "../../images/arrow-down.svg";

function FAQ({ faqData, backgroundColor, font, text }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showAll, setShowAll] = useState(false);

  const handleButtonClick = () => {
    setShowAll((prev) => {
      const newValue = !prev;
      if (!newValue) {
        setActiveIndex(-1); // Reset active index when toggling to "View Less"
      }
      return newValue;
    });
  };

  // Determine displayed FAQs based on the showAll state
  const displayedFAQs = showAll ? faqData : faqData.slice(0, 5);

  // Set height for View More and View Less states
  const viewMoreHeight = "900px";
  const viewLessHeight = "630px";
  
  return (
    <div className="p-4 bg-drkYellow">
      <section className="bg-drkYellow pos-faq-section transition-all duration-300">
        <div
          className="xl:flex md:flex justify-between lg:px-12 py-10 px-10 relative"
          style={{
            backgroundColor,
            fontFamily: font,
            color: text,
            overflow: "hidden",
            height: showAll ? viewMoreHeight : viewLessHeight,
          }}
        >
          <p className="text-[39px] md:w-[50%] font-medium uppercase">
            Frequently<br />Asked Questions.
          </p>
          <div className="w-full md:w-[50%]">
            <div className="faq-content">
              {displayedFAQs.map((item, index) => (
                <FAQItem
                  key={index}
                  question={item.question}
                  answer={item.answer}
                  isActive={activeIndex === index}
                  index={index}
                  setActiveIndex={setActiveIndex}
                />
              ))}
            </div>
          </div>
        
          <div
            className="flex justify-end text-xl mt-8"
            style={{
              position: "absolute",
              bottom: showAll ? "30px" : "30px",
              right: "22%",
              textAlign: "center",
            }}
          >
            <button onClick={handleButtonClick} className="view-more-button flex items-center">
              {showAll ? "View Less" : "View More"}
              <div className="ml-2">
                <img src={arrowdown} className={`w-[28px] transform ${showAll ? "rotate-180" : ""}`} alt="Arrow Down" />
              </div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

const FAQItem = ({ question, answer, isActive, index, setActiveIndex }) => {
  const handleToggle = () => {
    setActiveIndex(isActive ? -1 : index); // Toggle the active index for the clicked question
  };

  return (
    <div id={`pos-faq-item-${index}`} className={`pos-faq-item ${isActive ? "active" : ""}`}>
      <div
        className="faq-question text-base cursor-pointer"
        onClick={handleToggle}
      >
        <span className="text-xl">{question}</span>
        <span className="faq-toggle">{isActive ? "-" : "+"}</span>
      </div>
      {isActive && <div className="faq-answer">{answer}</div>}
    </div>
  );
}

export default FAQ;
