import React from "react";
import { Link } from "react-router-dom";
import RetailBg from "../../images/RetailBgImg.svg";
import RetailLeft from "../../images/RetailLeftImg.svg";
import RetailRight from "../../images/RetailRightImg.svg";
import RetailBgMobile from "../../images/RetailBgImgMobile.svg";

const OrderLine = ({ title, items }) => {
  return (
    <>
      <section className="xl:py-12 container m-auto">
        <div className="xl:w-[60%] text-center font-[Quicksand]  m-auto">
          <h4 className="text-headerText mb-4 xl:text-[70px] font-normal uppercase RetailTitle leading-[44px] md:leading-[84px]">
            {title}
          </h4>
        </div>
        {items.map((item) => (
          <div className="flex justify-center flex-row gap-4">
            {item.buttons.map((button, idx) => (
              <Link to={button.linkUrl} key={idx}>
                <button className="m-auto my-2 px-6 py-2 font-[Poppins] bg-headerText text-white rounded-full text-center block uppercase font-500">
                  {button.text}
                </button>
              </Link>
            ))}
          </div>
        ))}
        <div className="animation-container">
          <div className="flex justify-center">
            <img src={RetailBg} alt="Background" className="background-image" />
          </div>
          <img
            src={RetailLeft}
            alt="Left"
            className="left-image moving-animation"
          />
          <img
            src={RetailRight}
            alt="Right"
            className="right-image moving-animation1"
          />
        </div>
        <img className="m-auto RetailBgMobile" src={RetailBgMobile} alt="" />
      </section>
    </>
  );
};

export default OrderLine;
