import React from 'react';
import bg from '../../images/vivid-bg.svg';
import Qr from "../../images/qrcode.svg"
const Qrcode = () => {
  return (
    <div className='bg-black text-white font-onest '>
      {/* <div className='flex flex-row w-[90%] justify-center items-center mx-auto relative'> */}
        
        {/* Content Section with Border */}
       
        {/* <div className='flex flex-col justify-center items-start z-10'>
          <div className='relative text-left items-center w-[80%] p-16 pr-0 overflow-hidden my-20 rounded-bl-[170px] rounded-tl-[170px] border-[1px] border-r-0 border-gray'>
          <div className='w-[70%]'>
            <p className='text-[39px] font-medium'>Free add-on</p>
            <p className='text-drkYellow py-2 text-[20px] font-normal'>Repair Service Module</p>
            <p className='text-[15px] font-light'>
              Efficiently manage walk-in customers for repair services with our simple and effective repair service add-on. Effortlessly create job sheets, assign tasks to technicians, allocate spare parts from inventory, and oversee the entire repair process through to completion—all within an intuitive and efficient interface.
            </p>
            </div>
          </div>
        </div> */}
        
        {/* Enlarged Image Element positioned to touch the bordered section */}
        {/* <div className='absolute right-[0%] top-[-10%] z-10 overflow-hidden'>
          <img 
            src={bg} 
            className='w-[100%]  h-auto object-cover transform  z-20' 
            alt="Overlay" 
          />
        </div>
      </div> */}
      <div className='p-10'>
        <div className='items-center mx-auto justify-center flex flex-col'>
        <h2 className='text-center text-[32px] leading-10'>GrowRetail Retailer App and LocSto Customer app and website</h2>
        <p className='py-4'>Get benefited from GrowRetail and Locsto - Sync your inventory in just one click to maximize reach and grow your business effortlessly.</p>
      </div>
      <div className='w-full  m-auto flex flex-row gap-10 py-10'>
        <div className='w-[60%] bg-[#151414] border-drkYellow border-[1px] p-10 rounded-[40px] border-l-[0px] rounded-l-none'>
          
          <h3 className='text-[32px] leading-10'>GrowRetail</h3>
          <p className='text-[20px] leading-8 font-light py-2'>Share and Receive Stock with nearby retail stores</p>
          <p className='text-[23px] leading-8 pt-4 text-drkYellow'>Never Say ‘Out of Stock’ Again!"</p>
          <p className='text-[16px] py-2 leading-6 font-extralight'>When customers visit your store, make sure they never hear "No Stock." With the GrowRetail App, you can check stock availability at nearby stores if an item is out of stock. Here’s how it works:</p>
          <p className='text-[16px] py-2 leading-6 font-extralight'>If a customer’s requested product isn’t available, simply check the GrowRetail app for stock at the closest store and send a notification instantly. Nearby shops respond within minutes to confirm availability. You can then pick up the item and delight your customer by delivering exactly what they want!</p>
          <p className='text-[16px] leading-6 font-extralight'>Plus, you can share your products, offers, and store details on the GrowRetail app, driving local customers to shop with you instead of online.</p>
          </div>
        <div className='w-[40%] px-10'>
          <img src={Qr}/>
        </div>
      </div>
      <div className='w-full m-auto flex flex-row gap-10 py-10'>
      <div className='w-[40%] px-10'>
          <img src={Qr}/>
        </div>
        <div className='w-[60%] bg-[#151414] border-drkYellow border-[1px] p-10 rounded-[40px]  border-r-[0px] rounded-r-none'>
          <h3 className='text-[32px] leading-10'>LocSto</h3>
          <p className='text-[23px] leading-9 py-4 text-drkYellow'>Let People Say Goodbye to Online Shopping with the LOCSTO App!</p>
          <p className='text-[16px] leading-6 font-extralight'>Why wait endlessly for orders to arrive or worry about tricky return policies? With LOCSTO, there’s no more hesitation, especially when buying reliable pre-owned phones or other essential items. LOCSTO makes local shopping easy, fast, and dependable.</p>
          <p className='text-[16px] py-2 leading-6 font-extralight'>With the LOCSTO App, customers can quickly search for products available in nearby stores, find exactly what they’re looking for, and contact the store directly—no middlemen, no delays. They can enjoy instant shopping and immediate product availability.</p>
          <p className='blinking-cursor w-[70%] border-rgb(150,145,145) mt-2 border-[0.5px] bg-black rounded-[200px] text-[18px] leading-6 font-extralight p-4'>www.locsto.com <span class="cursor"> |</span></p>
          
        </div>
      </div>
      </div>
    </div>
  );
};

export default Qrcode;
