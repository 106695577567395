import React from "react";
import { Link } from "react-router-dom";
import downarrow from "../../images/circle-arrow-down 1.svg";

function Herosection({ title, buttonText, linkUrl, features }) {
  // Scroll to Slider Section function
  const scrollToSlider = () => {
    const sliderSection = document.getElementById("section-slider");
    if (sliderSection) {
      sliderSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to Next Section function
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("next-section");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="py-14 bg-hero-section relative md:px-8 xs:px-4 sm:px-4">
      <div className="container relative h-full">
        {/* Background decorative circles */}
        <div className="circle-1 "></div>
        <div className="circle-2 "></div>
        <div className="circle-3 "></div>
        <div className="circle-4 "></div>

        {/* Content */}
        <div className="relative flex flex-col items-center justify-center h-full md:py-32 xs:py-12 md:pt-16 sm:py-12">
          {/* Title */}
          <h1 className="text-black xl:text-[45px] lg:text-[80px] lg:leading-[100px] md:text-5xl text-center font-medium md:font-semibold font-quicksand mb-4 hero-font tab-hero-text-size">
            {title}
          </h1>

          {/* "Take a Look" Button */}
          <button
            onClick={scrollToSlider}
            className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-full mb-2 mt-0 md:mt-2 uppercase font-500"
          >
            {buttonText}
          </button>

          {/* Features Section */}
          <div className="block md:flex flex-wrap justify-center md:justify-start">
            {features.map((feature, index) => (
              <Link to={feature.link} key={index}>
                <div
                  className={`flex items-center bg-white shadow-md rounded-full md:rounded-3xl p-4 md:p-3 w-max border border-custom shadow-custom my-4 md:absolute ${feature.position}`}
                >
                  <img
                    src={feature.image}
                    alt={feature.label}
                    className="w-12 h-12 mr-2 object-cover"
                  />
                  <p className="text-blackGray font-normal text-xl">
                    {feature.label}
                  </p>
                </div>
              </Link>
            ))}
          </div>

          {/* Down Arrow Image to Scroll to Next Section */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 tab:bottom-2">
            <img
              src={downarrow}
              alt="Scroll to Next Section"
              className="w-10 h-12 cursor-pointer"
              onClick={scrollToNextSection}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Herosection;
