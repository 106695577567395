// src/components/Layout.js
import React from "react";
import LeadsHeader from "./LeadsHeader";
import LeadsFooter from "./LeadsFooter";
import { Outlet } from "react-router-dom";

const LeadsLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <LeadsHeader />
      <main className="flex-grow">
        <Outlet />
      </main>
      <LeadsFooter />
    </div>
  );
};

export default LeadsLayout;
