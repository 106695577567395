import React from "react";
import { Link } from "react-router-dom";
import Round from "../../images/mobile-round.svg";

function MobileHeroSection({ title, buttonText, features, onButtonClick }) {
  return (
    <section className="py-14 bg-hero-section relative md:px-8 xs:px-4 sm:px-4">
      <div
        className="absolute inset-0 z-0 bg-rotate"
        style={{
          backgroundImage: `url(${Round})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: "contain",
          borderRadius: "50%",
          transformOrigin: "center",
          zIndex: "-1",
          width: "400px",
          height: "400px",
        }}
      ></div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full md:py-36 xs:py-12 sm:py-12">
        <h1 className="text-black text-4xl sm:text-4xl md:text-7xl text-center font-medium mb-4 tab-hero-text-size">
          {title}
        </h1>
        <button
          onClick={onButtonClick} // Use the passed function
          className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-full mb-2 mt-0 md:mt-2 uppercase font-500"
        >
          {buttonText}
        </button>
        <div className="block md:flex flex-wrap justify-center md:justify-start">
          {features.map((feature, index) => (
            <Link key={index} to={feature.link}>
              <div
                className={`flex items-center bg-white shadow-md rounded-full md:rounded-3xl p-4 md:p-3 w-max border border-custom shadow-custom my-4 md:absolute ${feature.position}`}
              >
                <img
                  src={feature.image}
                  alt={feature.label}
                  className="w-12 h-12 mr-2 object-cover"
                />
                <p className="text-blackGray font-normal text-xl">
                  {feature.label}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MobileHeroSection;
