import React from 'react'

const Leadhead = () => {
  return (
    <section>
    <div>
      hi
    </div>
    </section>
  )
}

export default Leadhead
