import React from "react";
import Lottie from "react-lottie";
import clock from "../../images/clockin.png"

function Centerimgsection({
  title,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  content,
  content1,
  content2,
  content3,
  content4,
  content5,
  content6,
  imageUrl,
  backgroundColor,
  animationData,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className="py-8 relative container mx-auto">
      <div className="relative z-0 items-center justify-center h-full text-center px-4 md:px-8">
        <div
          className="border-custom-radius p-10 md:p-20"
          style={{ backgroundColor: backgroundColor }}
        > <div className="flex">
          <div>
          <h4 className="text-black text-3xl md:text-4xl font-400 mb-4 font-urbanist font-bold leading-[40px] md:leading-[54px]">
            {title}
          </h4>
          <p className="text-black mb-8 font-urbanist text-left text-lg tracking-normal">{content}</p>
          </div>
          <div>
           <img src={clock}/>
           </div>
          </div>
          <h4 className="text-black text-3xl md:text-4xl font-400 mb-4 font-urbanist font-bold leading-[40px] md:leading-[54px]">
            {title1}
          </h4>
          <p className="text-black mb-4 font-urbanist text-left text-lg tracking-normal">{content1}</p>
          <p className="text-black mb-4 font-urbanist text-left text-lg tracking-normal">{content2}</p>
          <p className="text-black font-urbanist text-left text-lg tracking-normal">{content3}</p>
          <p className="text-black mb-8 font-urbanist text-left text-lg tracking-normal">{content4}</p>
          {/* {animationData ? (
            <Lottie options={defaultOptions} />
          ) : (
            <img src={imageUrl} alt="Center Image" />
            )} */}
            <h4 className="text-black text-3xl md:text-4xl font-400 mt-4 mb-4 font-urbanist font-bold leading-[40px] md:leading-[54px]">
            {title2}
          </h4>
          <p className="text-black mb-8 font-urbanist text-left text-lg tracking-normal">{content5}</p>
          <h4 className="text-black text-3xl md:text-4xl font-400 mt-4 mb-4 font-urbanist font-bold leading-[40px] md:leading-[54px]">
            {title3}
          </h4>
          <p className="text-black mb-8 font-urbanist text-left text-lg tracking-normal">{content6}</p>
        </div>
      </div>
    </section>
  );
}

export default Centerimgsection;
