import React from "react";
import { useNavigate } from "react-router-dom";

const MobileProductTour = React.forwardRef(({ title, items }, ref) => {
  const navigate = useNavigate(); // Get the navigate function

  const handleViewMore = (link, type) => {
    if (type === "internal") {
      // For internal navigation using react-router
      navigate(link);
      window.scrollTo(0, 0);
    } else {
      // For external links
      window.open(link, "_blank");
    }
  };

  return (
    <section className="py-8 md:py-14" id="section-slider" ref={ref}>
      <h2 className="text-4xl text-center font-medium my-12">{title}</h2>
      <div className="mx-auto px-6 md:px-8 pt-2 z-10">
        {items.map((item, index) => (
          <div
            className="flex flex-col md:flex-row items-center gap-8"
            key={index}
          >
            <div className="w-full md:w-1/2 bg-white card-shadow p-8 rounded-3xl mb-6">
              <div className="items-start gap-4">
                <div className="w-1/3 md:w-1/4">
                  <img
                    src={item.touricon}
                    alt={`icon${index}`}
                    className="object-cover w-full mb-7"
                  />
                </div>
                <div className="w-full">
                  <h4 className="text-headerText text-2xl font-medium mb-3">
                    {item.subtitle}
                  </h4>
                  <p className="text-sm md:text-base">{item.tourcontent}</p>
                  <div className="flex gap-2 mt-4">
                    {item.buttons.map((button, idx) => (
                      <button
                        key={idx}
                        onClick={() =>
                          handleViewMore(
                            button.linkUrl,
                            button.linkUrl.startsWith("http")
                              ? "external"
                              : "internal"
                          )
                        }
                        className={`px-4 py-2 ${
                          button.type === "filled"
                            ? "bg-blue-500 text-white hover:bg-blue-600 rounded-full"
                            : "border border-blue-500 text-blue-500 hover:bg-blue-100 rounded-full"
                        } text-center text-sm`}
                      >
                        {button.text}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

export default MobileProductTour;
