import React from "react";

// Circle Icon Component
export const CircleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="circle-arrow-icon">
      <img src={require("../../images/arrow-circle-right.svg")} alt="next" />
    </div>
  );
};

export const CirclePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="circle-arrow-icon">
      <img src={require("../../images/arrow-circle-left.svg")} alt="previous" />
    </div>
  );
};

// Square Icon Component
export const SquareNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="square-arrow-icon">
      <img src={require("../../images/arrow-right.svg")} alt="next" />
    </div>
  );
};

export const SquarePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="square-arrow-icon">
      <img src={require("../../images/arrow-left.svg")} alt="previous" />
    </div>
  );
};
