import React from "react";
import Lottie from "react-lottie";
import animationData from "../../images/updated-1.json";
import animationData1 from "../../images/updated-2.json";

const Business = ({ title, items }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <section className="pt-28 businessTopMobile ">
        <div className="mx-auto pl-8 pt-2 z-10 animationPadding  m-auto container">
          <div className="flex flex-row text-center mb-12 ">
            <div className="basis-full font-[Poppins]">
              <h4 className="text-headerText text-[39px] font-medium xl:w-[40%] xl:m-auto animationTitle">
                {title}
              </h4>
            </div>
          </div>
          {items.map((item, index) => (
            <div
              className="xl:flex items-center basis-full max-w-full gap-8 "
              key={index}
            >
              <div className="BusinessMobile">
                <div className="flex mb-2 items-center gap-2 xl:pt-10">
                  <Lottie options={defaultOptions} />
                </div>
              </div>

              <div className="basis-3/5">
                <div className="">
                  <div className="xl:basis-8/12">
                    <h4 className="text-headerText text-[39px] font-[Poppins] font-medium mb-3 subtitle">
                      {item.subtitle}
                    </h4>
                    <p className="font-[Poppins]">{item.tourcontent}</p>
                  </div>
                </div>
              </div>
              <div className="BusinessDesktop">
                <div className="flex mb-2 items-center gap-2 pt-10">
                  <Lottie options={defaultOptions} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="xl:py-14">
        <div className="mx-auto px-8 pt-2 z-10  m-auto container">
          <div className="flex flex-row text-center mb-12"></div>
          {items.map((item, index) => (
            <div
              className="xl:flex items-center basis-full max-w-full gap-8"
              key={index}
            >
              <div className="basis-6/12">
                <div className="flex mb-2 items-center gap-2">
                  <Lottie options={defaultOptions1} />
                </div>
              </div>
              <div className="basis-6/12">
                <div className="">
                  <div className="xl:basis-8/12 font-[Poppins]">
                    <h4 className="text-headerText text-[39px] font-medium mb-3 subtitle">
                      {item.subtitle}
                    </h4>
                    <p className="font-[Poppins]">{item.tourcontent}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Business;
