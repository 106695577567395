// src/components/Layout.js
import React from "react";
import RetailShopHeader from "./RetailShopHeader";
import RetailShopFooter from "./RetailShopFooter";
import { Outlet } from "react-router-dom";

const RetailShopLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <RetailShopHeader />
      <main className="flex-grow">
        <Outlet />
      </main>
      <RetailShopFooter />
    </div>
  );
};

export default RetailShopLayout;
