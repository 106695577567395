import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarRatings from "react-star-ratings";
// import { CircleNextArrow, CirclePrevArrow, SquareNextArrow, SquarePrevArrow } from "./CustomArrows";
import { ReactComponent as CustomNextArrow } from "../../images/arrow-circle-right.svg";
import { ReactComponent as CustomPrevArrow } from "../../images/arrow-circle-left.svg";

const TestimonialSlider = ({
  testimonials,
  slidesToShowDesktop,
  slidesToShowMobile,
  backgroundColor,
  text, text1,font,
  backgroundColor1,
  // CustomNextArrow,
  // CustomPrevArrow 
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShowDesktop,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShowMobile,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  
  return (
    <section className=" px-8  " style={{backgroundColor:backgroundColor1}}>
      <div
        className="relative z-0 items-center justify-center h-full w-full md:w-9/12 p-12 m-auto rounded-3xl text-left"
        style={{ backgroundColor,color:text, fontFamily:font }} // Use the backgroundColor prop here
      >
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-slide">
              <h4 className=" text-xl md:text-3xl text-left font-semibold mb-2"style={{color:text1}}>
                {testimonial.author}
              </h4>

              <p className="mt-2 mb-6">{testimonial.quote}</p>
              <StarRatings
                rating={testimonial.rating} // Assuming rating is provided in the testimonial data
                starRatedColor="black"
                numberOfStars={5}
                name="rating"
                starDimension="20px"
                starSpacing="5px"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrow next-arrow`}
      style={{
        ...style,
        display: "block",
        background: "none",
        border: "1px solid black",
        borderWidth: "0 2px 2px 0",
        transform: "rotate(-45deg)",
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrow prev-arrow`}
      style={{
        ...style,
        display: "block",
        background: "none",
        border: "1px solid black",
        borderWidth: "2px 0 0 2px",
        transform: "rotate(45deg)",
      }}
      onClick={onClick}
    />
  );
};

export default TestimonialSlider;
