import React from 'react'
import PointOfSale from '../../images/point-of-sale-logo.svg'
// function Grid ({title, items}) {
  
//   return (
//     <section className='pt-28  bg-drkYellow font-onest BusinessDesktop'>
//          <div>
//         <h4 className="text-headerText text-[39px] text-center font-medium xl:w-[40%] xl:m-auto animationTitle">
//                 {title}
//               </h4>
              
//               {items.map((item, index) => (
//                   <div className="flex flex-col p-4">
//               <h className="text-center px-12">{item.subtitle}</h>
//                   <div className="mt-10 grid grid-cols-2 gap-3 justify-between text-white mx-8">
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     </div>
//                     <div className="mt-3 grid grid-cols-3 gap-3 justify-between text-white mx-8">
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     </div>
//                     <div className="mt-3 grid grid-cols-2 gap-3 justify-between text-white mx-8">
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     </div>
//                     <div className="mt-3 mb-20 grid grid-cols-3 gap-3 justify-between text-white mx-8">
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     <div className="bg-black p-5 rounded-2xl ">
//                       {/* {item.touricon} */}
//                       <img src={PointOfSale}/>
//                      <p className="text-2xl pb-2 pt-4">{item.tourheader}</p>
//                       <p className="text-gray leading-7 w-[95%]">{item.tourcontent}</p>
//                     </div>
//                     </div>
//                 </div>
//                  ))}
//         </div>
//     </section>
//   )
// }
// import React from 'react';
// import PointOfSale from '../../images/point-of-sale-logo.svg';

function Grid({ title, subtitle, items }) {
  const rowLength = 2;  // Set to 2 for 2 items per row
  
  // Split items into rows dynamically (group items in sets of 2)
  const rows = [];
  for (let i = 0; i < items.length; i += rowLength) {
    rows.push(items.slice(i, i + rowLength));
  }

  // Limit to 3 rows, adjust based on your needs
  const limitedRows = rows.slice(0, 3);

  return (
    <div id='features-section'>
    <section className="py-10 md:py-28 bg-drkYellow font-onest BusinessDesktop">
      
        {/* Render the single title and subtitle */}
        <h4 className="text-headerText text-[39px] text-center font-medium xl:w-[50%] md:mx-auto mx-2 animationTitle">
          {title}
        </h4>
        <h2 className="text-center px-12 py-4 text-base md:text-xl font-normal md:mb-6">{subtitle}</h2>

        {/* Render the first 3 rows with 2 columns per row */}
        {limitedRows.map((row, index) => (
          <div
            key={index}
            className="grid md:grid-cols-2 gap-2 md:gap-6 md:justify-between py-1 px-6 md:px-0 md:py-3 text-white md:mx-8"
          >
            {row.map((item, itemIndex) => (
              <div key={itemIndex} className="bg-black p-5 rounded-2xl">
                <img src={PointOfSale} alt="Icon" className='h-6 w-6 md:w-auto md:h-auto' />
                <p className="text-xl font-semibold md:font-normal md:text-2xl pb-2 pt-4">{item.tourheader}</p>
                <p className="text-gray text-sm md:text-base leading-5 md:leading-7 w-[95%]">{item.tourcontent}</p>
              </div>
            ))}
          </div>
        ))}
     
    </section>
    </div>
  );
}



export default Grid;

