// src/components/PrivacyPolicy.js
import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | The ERP Solution</title>
        <meta
          name="description"
          content="This is the SEO description for Tazk."
        />
        <meta name="keywords" content="landing page, seo, example" />
        <meta property="og:title" content="Privacy Policy | The ERP Solution" />
        <meta
          property="og:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        <meta property="og:image" content="../images/favicon.ico" />
        <meta property="og:url" content="https://tazk.in/privacy-policy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Privacy Policy | The ERP Solution"
        />
        <meta
          name="twitter:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
      </Helmet>
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
        <p className="mb-4">
          At VEETEE TECHNOLOGIES PRIVATE LIMITED, including its subsidiaries and
          affiliated entities ("we," "us," "our," "the Company," "VEETEE,"
          "TAZK.IN," "Service," "Site," "Product"), we are committed to
          safeguarding the privacy of individuals whose personal information
          ("Personal Data," "Information") we collect and process. This Privacy
          Policy outlines the Company's practices regarding the collection, use,
          and protection of Personal Data and other information obtained from
          visitors ("you," "your," "visitor") to our Site.
        </p>
        <p className="mb-6">
          Please be advised that your access to and use of the Site and any of
          our Services are governed by our Terms of Service, available on our
          homepage. Both this Privacy Policy and the Terms of Service
          collectively govern your interaction with the Site. By continuing to
          use the Site, you are deemed to have accepted and agreed to the Terms
          of Service and this Privacy Policy in full.
        </p>
        <h2 className="text-2xl font-semibold mb-4">
          Scope of this Privacy Policy
        </h2>
        <p className="mb-4">
          This Privacy Policy governs the treatment of personal data for
          individuals or entities who are not "Customers" of TAZK.in, i.e.,
          those who have not subscribed to our services or agreed to the
          relevant Terms of Service. It applies to all information you submit to
          us through the Site.
        </p>
        <p className="mb-4">
          Under GDPR and European Economic Area data protection laws, the
          Customer who provides End Users access to the Service is the data
          controller. For data collected via our website or any data processed
          outside the Service, TAZK.IN acts as the data controller
          ("Controller").
        </p>
        <p className="mb-4">
          If you are a Customer of TAZK.IN, meaning you have subscribed to our
          services and agreed to the applicable Terms of Service, or if you are
          an employee of a customer who accesses our services through your
          employer's subscription, this Privacy Policy is not applicable to you.
          Instead, the collection, use, and processing of your personal data
          will be governed by a separate Privacy Policy specifically tailored
          for Customers. This policy is linked to the application, platform, or
          service you use and is available for review directly through that
          platform or service. We encourage you to refer to and familiarize
          yourself with that specific Privacy Policy, as it outlines the data
          practices relevant to your use of our products and services in a more
          detailed and applicable context.
        </p>
        <p className="mb-4">
          In the event that you have provided information to any third parties
          via our Site or Services, the privacy policies of those third parties
          will govern the processing and use of your data, and we disclaim any
          responsibility for the same.
        </p>
        <h2 className="text-2xl font-semibold mb-4">
          Data that we Collect and Controls
        </h2>
        <p className="mb-4">
          TAZK.IN collects personal data only for legitimate purposes, either
          provided directly by the user, automatically obtained, or acquired
          from third parties. The specifics are as follows:
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-4">
          1. Data Provided by Users:
        </h2>
        <p className="mb-4">
          We may collect the following categories of personal data:
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Account Creation: </span> Users must
              provide personal details such as name, email address, phone
              number, company name, and country. Optional information, including
              a profile photo and time zone, may also be submitted. Security
              questions may be added for password recovery.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                Event Registrations and Form Submissions:
              </span>
              Data is collected during event registrations, newsletter
              subscriptions, material requests, or customer support
              interactions.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Payment Processing: </span> During
              transactions, users provide payment details. TAZK.IN retains only
              the last four digits of the credit card number and the
              cardholder’s name and address, storing full card numbers securely
              only with user consent.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Testimonials: </span> With user
              authorization, testimonials may be published, including personal
              information. Users may review and request modifications or
              deletions of their testimonials.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Interactions with TAZK.IN: </span>{" "}
              Communications through email, telephone, or chat with TAZK.IN’s
              staff may be recorded and analysed for service improvement
              purposes.
            </li>
          </ul>
        </p>
        <h2 className="text-xl font-semibold mt-4 mb-4">
          2. Data Automatically Collected:
        </h2>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                Browser, Device, and Server Information:{" "}
              </span>{" "}
              Information such as IP address, browser type, language, time zone,
              and operating system are collected from users' devices during
              their visit to TAZK.IN’s websites.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                Browser, Device, and Server Information:{" "}
              </span>{" "}
              Information such as IP address, browser type, language, time zone,
              and operating system are collected from users' devices during
              their visit to TAZK.IN’s websites.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                Cookies and Tracking Technologies:{" "}
              </span>{" "}
              TAZK.IN employs cookies and similar technologies to monitor
              website usage, track user interactions, and enhance user
              experience.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                Application Logs and Analytics:{" "}
              </span>{" "}
              Data related to app usage, including clicks, scrolls, and feature
              access, is collected for performance analysis and service
              improvement.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">IP addresses, </span> browser type,
              operating system, device identifiers, and network-related
              information.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Log Files: </span> We automatically
              collect log data regarding your use of the platform, such as
              access times, pages viewed, system interactions, and
              authentication details.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                Analytics and Platform Optimization:{" "}
              </span>{" "}
              To monitor usage patterns, system performance, and implement
              enhancements to the platform's user experience and security
              features.
            </li>
          </ul>
        </p>
        <h2 className="text-xl font-semibold mt-6 mb-4">
          3. Data from Third Parties:
        </h2>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Federated Authentication: </span>{" "}
              Users may log in using federated services (e.g., LinkedIn,
              Microsoft, Google), which may share personal data such as name and
              email address.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">Referrals and Partners: </span>{" "}
              Information may be provided by referral sources, reselling
              partners, or event organizers, including personal details
              necessary for specific purposes.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                {" "}
                Social media and Public Sources:{" "}
              </span>{" "}
              Publicly available data from social media and review sites may be
              collected to improve services and understand user feedback.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          TAZK.IN ensures that all data is managed in accordance with applicable
          legal requirements and used exclusively for its intended purposes.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          How We Utilize Your Information
        </h2>
        <p className="mb-4">
          At TAZK.IN, we prioritize your privacy and are committed to
          safeguarding your personal data. We do not sell, share, or transfer
          your information to third parties without your consent. We use your
          personal data in accordance with this policy.
        </p>
        <p className="mb-4">
          When you provide personal data to access our services, we utilize it
          to facilitate your access, process transactions, respond to inquiries,
          and address issues related to the services. Additionally, your
          personal and non-identifiable data may be used to enhance service
          content and functionality, gain insights into user behaviour, and
          develop new products, features, and services.
        </p>
        <p className="mb-4">
          We may use your contact information for administrative purposes,
          including customer service, and to address issues related to
          intellectual property, privacy, or defamation associated with content
          posted on our services.
        </p>
        <p className="mb-4">
          If you apply for a position and submit your information via an
          application form or resume, we will use this data to assess your
          application for current or future job openings at TAZK.IN. This
          information may be retained in our database for up to five years,
          unless otherwise specified.
        </p>
        <p className="mb-4">
          Occasionally, we may contact you with information about our services,
          updates, promotions, and events that may be of interest to you. If you
          prefer not to receive such communications, you can opt-out using the
          provided instructions or contact us directly to request removal from
          our mailing lists.
        </p>
        <p className="mb-4">
          We may analyse personal and other information to understand user
          demographics, interests, and behaviour. This aggregated data, which
          does not identify individuals, may be shared with affiliates, agents,
          business partners, or used to describe our services to potential
          partners and other third parties for lawful purposes.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Legal Grounds for Processing Personal Data (EEA)
        </h2>
        <p className="mb-4">
          For individuals in the European Economic Area (EEA), the legal basis
          for our collection and processing of personal data is determined by
          the nature of the data and the specific context in which it is
          collected. Typically, our processing activities are grounded in one or
          more of the following legal bases:
        </p>
        <p className="mb-4">
          <span className="font-bold"> 1. Contractual Necessity: </span> We
          process personal data when it is essential to fulfil a contract with
          you, or to take steps at your request prior to entering into such a
          contract.
        </p>
        <p className="mb-4">
          <span className="font-bold"> 2. Legitimate Interests: </span> We may
          process personal data based on our legitimate interests, provided
          these interests are not overridden by your data protection rights.
          These legitimate interests may include purposes such as improving our
          services, conducting direct marketing, and managing our business
          operations effectively.
        </p>
        <p className="mb-4">
          <span className="font-bold"> 3. Consent: </span> Where processing is
          based on your consent, we will obtain your explicit permission before
          collecting and processing your data. You retain the right to withdraw
          this consent at any time, and such withdrawal will not affect the
          legality of any processing conducted prior to your withdrawal.
        </p>
        <p className="mb-4">
          <span className="font-bold"> 4. Legal Obligation: </span> We may be
          required to process personal data to comply with legal obligations
          imposed on us.
        </p>
        <p className="mb-4">
          <span className="font-bold"> 5. Vital Interests: </span> In certain
          situations, processing may be necessary to protect your vital
          interests or those of another individual.
        </p>
        <p className="mb-4">
          If we rely on legitimate interests as the basis for processing your
          personal data, and those interests are not specified in this policy,
          we will provide a detailed explanation of those interests at the time
          of data collection. This ensures transparency regarding how and why
          your data is used.
        </p>
        <p className="mb-4">
          <p className="mb-4">
            In cases where consent is the legal basis for processing, you have
            the right to withdraw consent at any time, but this will not affect
            any processing that has occurred before the withdrawal. If you have
            any concerns or require further information regarding the legal
            grounds for processing your personal data, please contact us
            directly.
          </p>
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Data Sharing and Disclosure
        </h2>
        <p className="mb-4">
          We are committed to maintaining the confidentiality and security of
          your personal data. We will not sell, rent, or otherwise disclose your
          personal data to third parties except in the following circumstances:
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Service Providers: </span> We may
              disclose your personal data to trusted third-party service
              providers or business partners who perform functions on our
              behalf, such as cloud hosting, payment processing, and technical
              support. These third parties are bound by contractual obligations
              to safeguard your data and utilize it solely for the purposes
              specified in our agreements.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                {" "}
                Legal and Regulatory Compliance:{" "}
              </span>{" "}
              We may disclose your personal data to governmental authorities,
              regulatory bodies, or other third parties as required by
              applicable law or to comply with legal obligations. This may
              include responding to subpoenas, court orders, or other lawful
              requests and legal processes.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Corporate Transactions: </span> In
              the event of a merger, acquisition, sale, or other corporate
              restructuring, your personal data may be transferred to third
              parties involved in such transactions. Any such transfer will be
              subject to confidentiality agreements and privacy safeguards to
              ensure that your data remains protected in accordance with this
              policy.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          We ensure that any disclosures of personal data are conducted in
          accordance with legal requirements and with appropriate safeguards to
          protect your privacy.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Data Security and Protection
        </h2>
        <p className="mb-4">
          We are committed to ensuring the security and integrity of your
          personal data. Our robust security framework is designed to prevent
          unauthorized access, maintain data accuracy, and prevent unauthorized
          alteration or disclosure. The security measures we implement include:
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                {" "}
                Encryption and Secure Communication:{" "}
              </span>{" "}
              We utilize Transport Layer Security (TLS) technology when
              accessing services via modern web browsers. TLS provides server
              authentication and data encryption, safeguarding your data from
              unauthorized access and ensuring it is only accessible to
              authorized users and those granted access by the user.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Dynamic Security Methods: </span> Our
              security protocols include advanced measures based on dynamic data
              and encoded session identifiers to enhance protection against
              unauthorized access.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Secure Hosting Environment: </span>{" "}
              Our websites are hosted in a highly secure server environment,
              fortified with firewalls and cutting-edge technology to prevent
              unauthorized interference and access.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Data Encryption: </span> We apply
              encryption to data at rest, ensuring that stored information is
              protected from unauthorized access.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Access Control: </span> Access to
              personal data is restricted to authorized personnel only, enforced
              through stringent password and authentication procedures.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                {" "}
                Customizable Security Features:{" "}
              </span>{" "}
              We provide enhanced security features within our services,
              allowing customers to configure security settings according to
              their specific needs and preferences.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          In the event of a security breach, we will endeavour to promptly
          notify you through our website or by email, enabling you to take
          appropriate measures to protect your data.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">Data Retention</h2>
        <p className="mb-4">
          We retain personal data for as long as is necessary to fulfil the
          purposes for which it was collected, or as required by law. Upon the
          expiration of retention periods, data will be securely deleted or
          anonymized.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          International Data Transfers
        </h2>
        <p className="mb-4">
          If you are accessing our platform from outside the jurisdiction in
          which our servers are located, please be aware that your data may be
          transferred internationally. We take steps to ensure that such
          transfers comply with applicable data protection laws, including
          through the use of Standard Contractual Clauses (SCCs) and other
          appropriate safeguards.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">User Rights</h2>
        <p className="mb-4">
          You have the following rights concerning your personal data:
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Right to Access: </span> You can
              request a copy of the personal data we hold about you.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Right to Rectification: </span> You
              can request the correction of any inaccurate or incomplete data.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                {" "}
                Right to Erasure ("Right to be Forgotten"):{" "}
              </span>{" "}
              You can request the deletion of your personal data where it is no
              longer necessary for the purposes for which it was collected.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Right to Data Portability: </span>{" "}
              You can request a copy of your data in a structured,
              machine-readable format.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold">
                {" "}
                Right to Object/Restrict Processing:{" "}
              </span>{" "}
              You can object to certain data processing or request that we limit
              how we process your data.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              <span className="font-bold"> Right to Withdraw Consent: </span> :
              If you provided consent for specific processing activities, you
              may withdraw that consent at any time.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          Exercise these rights, please contact us at mail@tazk.in
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Data Protection for Minors
        </h2>
        <p className="mb-4">
          Our ERP platform is not intended for use by individuals under the age
          of 16, and we do not knowingly collect personal data from minors. If
          we become aware that a minor has provided personal data, we will take
          steps to delete such information.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Changes to this Privacy Policy
        </h2>
        <p className="mb-4">
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be posted on this page with an updated "Last
          Modified" date. Continued use of the platform following such changes
          constitutes your acceptance of the revised policy.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy or wish to
          exercise your data protection rights, please contact us at:
        </p>
        <p className="mb-4 text-xl font-bold">
          VeeTee Technologies Private Limited
        </p>
        <p className="mb-4">
          8/65, 1st Floor, Radhakrishnan Street, Shankaran Avenue{" "}
        </p>
        <p>Velachery, Chennai, Tamil Nadu, India </p>
        <p> Pin code – 600042.</p> <p>Email: mail@tazk.in</p>
        <p> Phone: +91 9500000404</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
