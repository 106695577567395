import React, { useEffect, useRef } from "react";
import projects from "../../images/projects.jpg";
import chat from "../../images/chats.png";

function Benifits({
  title,
  subtitle,
  content,
  subtitle1,
  content1,
  cardtitle1,
  cardcontent1,
  cardtitle2,
  cardcontent2,
  cardtitle3,
  cardcontent3,
  cardtitle4,
  cardcontent4,
}) {
  const benefitsRef = useRef(null);

  useEffect(() => {
    // Check if the URL matches the add-on path
    if (window.location.pathname === "/payroll/add-on") {
      // Scroll to the benefits section directly
      if (benefitsRef.current) {
        // Use scrollIntoView for a more precise scroll
        benefitsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, []);

  return (
    <section
      ref={benefitsRef}
      className="py-12 md:py-16 container mx-auto font-urbanist"
    >
      <div className="mx-4 md:mx-8 z-10">
        <div className="mb-10 text-center">
          <h4 className="text-black text-2xl md:text-[37px] lg:text-4xl font-semibold uppercase">
            {title}
          </h4>
          <p className="font-urbanist mt-6 md:mt-[38px] text-lg md:text-[32px] font-semibold text-[rgb(0,170,74)]">
            {subtitle}
          </p>
          <p className="mt-2 md:mt-[12px] w-[90%] md:w-[70%] mx-auto font-urbanist text-base md:text-lg text-center">
            {content}
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex flex-col md:w-1/3 gap-4">
            <div className="bg-white card-shadow p-4 md:p-8 rounded-3xl">
              <p className="text-headerText text-lg md:text-[24px] font-semibold">
                {cardtitle1}
              </p>
              <p className="text-sm md:text-lg">{cardcontent1}</p>
            </div>
            <div className="bg-white card-shadow p-4 md:p-8 rounded-3xl">
              <p className="text-headerText text-lg md:text-2xl font-semibold">
                {cardtitle3}
              </p>
              <p className="text-sm md:text-lg">{cardcontent3}</p>
            </div>
          </div>

          <div className="flex items-center justify-center md:w-1/3">
            <img
              src={projects}
              alt="projects"
              className="object-contain w-full h-auto rounded-lg"
            />
          </div>

          <div className="flex flex-col md:w-1/3 gap-4">
            <div className="bg-white card-shadow p-4 md:p-8 rounded-3xl">
              <p className="text-headerText text-lg md:text-2xl font-semibold">
                {cardtitle2}
              </p>
              <p className="text-sm md:text-lg">{cardcontent2}</p>
            </div>
            <div className="bg-white card-shadow p-4 md:p-8 rounded-3xl">
              <p className="text-headerText text-lg md:text-2xl font-semibold">
                {cardtitle4}
              </p>
              <p className="text-sm md:text-lg">{cardcontent4}</p>
            </div>
          </div>
        </div>

        <p className="font-urbanist mt-6 md:mt-[50px] text-lg md:text-4xl font-semibold text-[rgb(0,170,74)] text-center">
          {subtitle1}
        </p>
        <p className="mt-6 md:mt-[12px] w-[90%] md:w-[70%] mx-auto font-urbanist text-base md:text-lg text-center">
          {content1}
        </p>
        <div className="flex justify-center items-center">
          <img
            src={chat}
            className="mx-auto mt-10 w-[300px] md:w-[400px] h-auto"
            alt="Chat Image"
          />
        </div>
      </div>
    </section>
  );
}

export default Benifits;
