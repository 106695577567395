import React, { useState, useEffect, useRef } from "react";
import Logo from "../images/logo-tazk.svg";
import { Link } from "react-router-dom";
import MegaMenu from "../components/MegaMenu";

const Headermain = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const buttonRef = useRef(null); // Ref to the button
  const menuRef = useRef(null); // Ref to the MegaMenu

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeMenu();
      }
    };

    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };

    const closeMenu = () => {
      setIsMegaMenuOpen(false);
      if (buttonRef.current) {
        buttonRef.current.blur(); // Remove focus from button
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("keydown", handleKeyDown); // Add keydown event listener
    document.addEventListener("mousedown", handleClickOutside); // Add click event listener

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keydown", handleKeyDown); // Clean up keydown event listener
      document.removeEventListener("mousedown", handleClickOutside); // Clean up click event listener
    };
  }, []);

  const toggleMegaMenu = () => {
    setIsMegaMenuOpen((prev) => !prev);
    if (!isMegaMenuOpen && buttonRef.current) {
      buttonRef.current.blur(); // Remove focus when opening the menu
    }
  };

  return (
    <header
      className={`sticky top-0 z-20 transition duration-300 ease-in-out ${
        isSticky ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 md:px-8 py-4 z-10 flex justify-between items-center relative">
        <Link to="/">
          <img src={Logo} alt="Tazk" />
        </Link>
        <button
          ref={buttonRef} // Attach ref to button
          onClick={toggleMegaMenu}
          className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-full focus:outline-none" // Added focus:outline-none to remove focus styles
        >
          PRODUCTS
        </button>
        {isMegaMenuOpen && <MegaMenu ref={menuRef} />}{" "}
        {/* Attach ref to MegaMenu */}
      </div>
    </header>
  );
};

export default Headermain;
