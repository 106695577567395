// src/components/TermsAndConditions.js
import React from "react";
import { Helmet } from "react-helmet";

const Termsandconditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions | The ERP Solution</title>
        <meta
          name="description"
          content="This is the SEO description for Tazk."
        />
        <meta name="keywords" content="landing page, seo, example" />
        <meta
          property="og:title"
          content="Terms & Conditions | The ERP Solution"
        />
        <meta
          property="og:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        <meta property="og:image" content="../images/favicon.ico" />
        <meta
          property="og:url"
          content="https://tazk.in/terms-and-conditions"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Terms & Conditions | The ERP Solution"
        />
        <meta
          name="twitter:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
      </Helmet>
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
        <p className="mb-4">
          VeeTee Technologies Private Limited operates this website, mobile app,
          and related services (collectively, the “Service”). By using the
          Service, you agree to these Terms of Service.
        </p>
        <p className="mb-4">
          This Agreement is a binding contract between VeeTee Technologies
          Private Limited (“TAZK.IN”, “we,” “us,” or similar terms) and you or
          the entity you represent (“you,” “user,” “customer,” or “subscriber”).
        </p>
        <p className="mb-4">
          We may update these Terms at any time, and continued use of the
          Service indicates your acceptance of any changes. The latest version
          is available on our website.
        </p>
        <p className="mb-4">
          Violation of the terms may result in account termination. You use the
          Service at your own risk.
        </p>
        <p className="mb-4">
          This Site is intended for adult use only. Users must be of legal age
          to access and use this Site.
        </p>
        <p className="mb-4">
          TAZK.IN offers tools, software, and resources (collectively,
          “Services”) across various domains (“Sites”). Free trial users are
          “Users,” while paying users are “Subscribers” or “Customers.” If you
          represent a company, you confirm you have the authority to bind it to
          this Agreement.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Agreement Acceptance
        </h2>
        <p className="mb-4">
          By using our Site, you agree to the terms and conditions outlined in
          this Terms of Use Agreement ("Agreement"). This Agreement constitutes
          the entire agreement between you and TAZK.IN and supersedes any prior
          agreements or understandings related to the Site and its content,
          products, or services. We reserve the right to amend this Agreement at
          any time without specific notice. Any changes will be posted on the
          Site, and you are advised to review the Agreement regularly.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">Privacy Policy</h2>
        <p className="mb-4">
          Our Privacy Policy forms part of this Agreement. By using the Site,
          you consent to the collection, use, and disclosure of your personally
          identifiable information as described in the Privacy Policy, which may
          be updated from time to time.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4">Registration</h2>
        <p className="mb-4">
          By creating an account on the Site, you agree to the following:
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Provide accurate, current, and complete information as prompted
              during registration ("Registration Data").
            </li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>Ensure the confidentiality and security of your password.</li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Promptly update your Registration Data to keep it accurate and
              complete.
            </li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Accept responsibility for all activity that occurs under your
              account, including any charges incurred.
            </li>
          </ul>
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Restrictions on Use
        </h2>
        <p className="mb-4">
          Your license to access the Site is subject to certain restrictions.
          You may not:
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Copy, distribute, or transmit any part of the Site without
              permission.
            </li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Use the Site to create a database or information system for
              commercial purposes.
            </li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Use the content in a way that infringes on the intellectual
              property rights of TAZK.IN or third parties.
            </li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>Reverse-engineer or decompile any software on the Site.</li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Engage in activities that violate laws or send unsolicited
              communications via email, phone, or fax.
            </li>
          </ul>
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Fees and Payments</h2>
        <p className="mb-4">
          You represent and warrant that any credit information you provide is
          accurate and complete. You agree to pay all charges at the agreed-upon
          prices, including applicable taxes, and that your credit card company
          will honor those charges. TAZK.IN may suspend services for
          non-payment.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Free Trial Terms</h2>
        <p className="mb-4">
          By enrolling in a free trial with TAZK.IN, you are granted access to
          our services at no cost until one of the following events occurs: (i)
          the conclusion of the trial period (or an earlier termination by you),
          (ii) commencement of a paid subscription for the same services, or
          (iii) termination of the trial at TAZK.IN's discretion.
        </p>
        <p className="mb-4">
          The following conditions apply to the free trial:
        </p>

        <p className="mb-4">
          <span className="font-bold"> 1. Data and Customizations: </span> Any
          data input or customizations made during the free trial will be
          irrevocably lost unless you undertake one of the following actions
          before the end of the trial: (i) subscribe to a paid plan for the same
          services, (ii) opt for an upgrade to a relevant service, or (iii)
          export your data.
        </p>

        <p className="mb-4">
          <span className="font-bold"> 2. Service Provision: </span> Services
          during the free trial are provided on an "as-is" basis. TAZK.IN
          expressly disclaims all warranties, support, and liability to the
          maximum extent permitted by law. This means that the services are
          offered without any assurance of performance or quality, and we are
          not liable for any issues or damages arising from the use of the
          services during the trial.
        </p>

        <p className="mb-4">
          <span className="font-bold">
            {" "}
            3. No Guarantee for Free Accounts:{" "}
          </span>{" "}
          TAZK.IN makes no representations or guarantees regarding the
          protection, availability, or integrity of data associated with free
          accounts. Users should understand that free accounts may not benefit
          from the same level of data protection as paid subscriptions.
        </p>

        <p className="mb-4">
          By participating in the free trial, you acknowledge and accept these
          terms.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Policy on Inactive User Accounts
        </h2>
        <p className="mb-4">
          TAZK.IN reserve the right to terminate unpaid user accounts that have
          been inactive for 90 consecutive days. If your account is terminated,
          all associated data will be deleted. We will notify you before
          termination and offer an opportunity to back up your data. This policy
          applies to all Services individually; activity in one Service does not
          prevent inactivity in another. For accounts with multiple users, the
          account will remain active as long as at least one user is active.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Copyright</h2>
        <p className="mb-4">
          All content, design, graphics, and other elements of the Site are
          protected by applicable copyright, trademark, and intellectual
          property laws. Any unauthorized use, reproduction, or distribution of
          the Site's content is strictly prohibited. Some materials on the Site
          may be the copyrighted work of third parties.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Limited License and Permitted Uses
        </h2>
        <p className="mb-4">
          You are granted a non-exclusive, non-transferable, revocable license
          to:
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>Access and use the Site in accordance with this Agreement.</li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>Use the Site solely for personal, non-commercial purposes.</li>
          </ul>
        </p>

        <p className="mb-4">
          <ul className="list-disc list-inside ml-4">
            <li>
              Print content from the Site for personal use, provided all
              copyright and other proprietary notices are maintained.
            </li>
          </ul>
        </p>

        <p className="mb-4">
          The services provided through the Site are for your personal use only
          and may not be resold, leased, or provided to others in any way.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Third-Party Content
        </h2>
        <p className="mb-4">
          The Site may contain content provided by third parties or links to
          external websites. TAZK.IN is not responsible for the accuracy or
          reliability of any third-party content and assumes no liability for
          any damages arising from such content.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Prohibition of Spamming and Illegal Activities
        </h2>
        <p className="mb-4">
          You hereby agree to bear sole responsibility for the content of any
          communications or transmissions made through the Services. You shall
          not utilize the Services for any unlawful purposes or to transmit
          materials that are:
        </p>

        <p className="mb-4">
          <span className="font-bold"> 1. Illegal or Harmful: </span> Unlawful,
          defamatory, insulting, harassing, libelous, or abusive; infringing on
          the privacy or bodily privacy of others; threatening, harmful, vulgar,
          obscene, or pornographic; pedophilic, harmful to minors, or racially
          or ethnically objectionable.
        </p>

        <p className="mb-4">
          <span className="font-bold">2. Offensive or Dangerous: </span>{" "}
          Offensive to religious sentiments, promoting racism, or containing
          viruses or malicious code.
        </p>

        <p className="mb-4">
          <span className="font-bold">
            3. Intellectual Property Infringement:{" "}
          </span>{" "}
          Infringing upon or potentially infringing upon the intellectual
          property or other rights of third parties.
        </p>

        <p className="mb-4">
          <span className="font-bold">
            3. Intellectual Property Infringement:{" "}
          </span>{" "}
          Infringing upon or potentially infringing upon the intellectual
          property or other rights of third parties.
        </p>

        <p className="mb-4">
          <span className="font-bold">
            4. Spam and Unsolicited Communications:{" "}
          </span>{" "}
          Transmitting "junk mail," "spam," "chain letters," "phishing" schemes,
          or any form of unsolicited mass distribution of email.
        </p>

        <p className="mb-4">
          TAZK.IN reserves the right to terminate your access to the Services if
          there is reasonable cause to believe that you have engaged in any
          illegal or unauthorized activity. Such termination may be executed
          without prior notice and is at the sole discretion of TAZK.IN.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Errors and Changes</h2>
        <p className="mb-4">
          We do not guarantee that the Site will be free from errors or harmful
          components. We reserve the right to modify the content or features of
          the Site without notice and are not responsible for any inaccuracies
          or omissions.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Indemnification</h2>
        <p className="mb-4">
          You agree to indemnify and hold TAZK.IN, its affiliates, and partners
          harmless from any claims, losses, or liabilities arising from your
          violation of this Agreement or misuse of the Site.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Limitation of Liability
        </h2>
        <p className="mb-4">
          TAZK.IN will not be liable for any indirect, incidental, or
          consequential damages, including loss of profits or business
          interruption, resulting from your use or inability to use the Service,
          even if advised of the possibility. TAZK.IN’s total liability will not
          exceed ₹5,000 or the fees paid in the last twelve months, whichever is
          higher.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Disclaimer</h2>
        <p className="mb-4">
          The content and services on the Site are provided "as is" without
          warranties of any kind. TAZK.IN disclaims all warranties, express or
          implied, including fitness for a particular purpose. We are not liable
          for any bugs, errors, or defects in the Site.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Non-Transferability
        </h2>
        <p className="mb-4">
          Your right to use the Site is personal and non-transferable. Any
          account details, including passwords, are strictly for your use only
          and may not be shared or transferred.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Linking Policy</h2>
        <p className="mb-4">
          You may link to the Site, provided that you do not obscure the Site’s
          content or engage in illegal or offensive activities. TAZK.IN reserves
          the right to request the removal of any link at any time.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Unlawful Activity</h2>
        <p className="mb-4">
          We reserve the right to investigate any complaints or reported
          breaches of this Agreement. We may take appropriate action, including
          reporting suspected illegal activities to law enforcement or other
          relevant authorities. This may involve disclosing information related
          to your profile, email addresses, usage history, posted materials, IP
          addresses, and traffic data as necessary.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Indemnification</h2>
        <p className="mb-4">
          You agree to indemnify and hold TAZK.IN, its employees, suppliers, and
          affiliates harmless from any losses, damages, fines, or expenses
          (including legal fees) resulting from claims that arise from your use
          of the Services in violation of any laws, rights, or the Agreement,
          except where such use is expressly authorized by TAZK.IN.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">Legal Compliance</h2>
        <p className="mb-4">
          You agree to comply with all applicable laws and regulations regarding
          your use of the Site.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">
          Governing law and General Provisions
        </h2>
        <p className="mb-4">
          This Agreement shall be deemed to have been executed and performed in
          Chennai, India, and shall be governed by and construed in accordance
          with the laws of the Union of India. Any disputes arising out of or in
          connection with this Agreement shall be subject to these laws. The
          language of this Agreement shall be construed to reflect its fair
          meaning and not construed strictly against any party.
        </p>
        <p className="mb-4">
          In the event of a merger, acquisition, or sale, we may assign this
          Agreement and all related information to a third party at our sole
          discretion. If any provision of this Agreement is found to be invalid
          or unenforceable, such provision shall be modified to conform to
          applicable law, while the remaining provisions shall continue in full
          force and effect.
        </p>

        <p className="mb-4">
          In the event of any inconsistency between this Agreement and any
          content on the Site, this Agreement shall prevail. Failure to enforce
          any provision of this Agreement shall not be construed as a waiver of
          our right to enforce such provision in the future. All rights and
          obligations under this Agreement shall survive termination or
          expiration of the Agreement.
        </p>
      </div>
    </>
  );
};

export default Termsandconditions;
