import React from "react";

function Title({ title }) {
  return (
    <section className="px-4 md:px-8 bg-white md:pt-10 ">
      <div className="relative z-0 text-center">
        <h4 className="text-black font-[Quicksand] text-3xl md:text-4xl lg:text-5xl font-semibold md:font-semibold uppercase leading-9">
          {title}
        </h4>
      </div>
    </section>
  );
}

export default Title;
