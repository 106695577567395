import React, { useEffect, useRef, useState } from "react";
import Tick from "../../images/Tick.svg";
import { useLocation } from "react-router-dom";
import getServerConfig from "../../http-common";
import axios from 'axios';

const Subscription = () => {
  const [active, setActive] = useState("MONTH");
  const [selectedPlan, setSelectedPlan] = useState("STARTER"); // State to store selected plan
  const featuresRef = useRef(null);
  const location = useLocation();
  const [responseData, setResponseData] = useState([])

  const host = getServerConfig('getHost', 1)


  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value); // Update the selected plan
  };
  
  useEffect(() => {
    if (location.pathname === "/payroll/subscription") {
      // Scroll into view at the top of the section
      featuresRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [location]);
  useEffect(() => {
    // Check if the device is an Android device
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      // Add a class to the <body> element for Android browsers
      document.body.classList.add("android-browser");
    }
  }, []); 
  useEffect(() => {
    axios.get(`${host}/subscriptionPlans/getSubscriptionPlans`, {
      headers: {
        'Content-type': 'application/json',
      },
    })
    .then((res) => {
      setResponseData(res.data)
      console.log(res.data)
    })
    .catch((err) => console.log(err))
  }, [])

  const handleStartFreeTrialId1 = () => {
    const redirectUrl = getServerConfig('5', 1); 
    // window.location.href = redirectUrl; // Redirect the user
    console.log('Opening URL:', redirectUrl);
     window.open(redirectUrl, '_blank');
  };

  // Handle another action with id=4
  const handleStartFreeTrialId4 = () => {
    const redirectUrl = getServerConfig('5', 4); 
    console.log('Opening URL:', redirectUrl);
    // window.location.href = redirectUrl; // Redirect the user
    window.open(redirectUrl, '_blank');
  };

  return (
    <>
      <div className="bg-Green font-urbanist">
      <section
        ref={featuresRef}
        className="mt-8 px-10 py-14 subscriptionDesktop container m-auto"
      >
        <div className="overflow-x-auto">
          <table className="w-full text-left border-collapse border-[1px] border-[#54F59A]">
            <thead>
              <tr>
                <th className="p-4 border-[#54F59A]">
                  <p className="mt-2 text-[25px] font-medium text-headerText">
                    Compare plans
                  </p>
                  <p className="mt-1 text-[14px] font-normal text-LightGray">
                    Choose your workspace plan <br />
                    according to your <br />
                    organisational plan
                  </p>
                </th>
                <th className="p-4 border-[#54F59A] text-center">
                  <p className="text-lg font-medium text-green-500">
                    STARTER
                  </p>
                  <p className="invisible"> </p>
                  <p className="text-headerText text-[39px] font-bold">
                    {/* <s className="text-LightGray text-[24px] font-medium mr-2 invisible">
                      3000₹
                    </s>{" "} */}
                    {/* Invisible strike-through for layout consistency */}
                    Free
                    <span className="text-[14px] font-medium text-headerText">
                      {/* /Lifetime */}
                    </span>
                  </p>
                  <p className="mt-1 font-semibold text-gray-500">
                  Up to {`${responseData[0]?.User_Count}`} Users
                  </p>
                  <button className="px-6 py-2 mt-4 font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText"
                    onClick={handleStartFreeTrialId1}
                  >
                    Register Now
                  </button>
                </th>
                <th className="p-4 border-[#54F59A] text-center">
                  <p className="text-lg font-medium text-GreenText">
                    ESSENTIAL
                  </p>
                  <p className="text-headerText text-[39px] font-bold">
                    <s className="text-LightGray text-[24px] font-medium mr-2">
                    {`${responseData[1]?.Original_Price}₹`}
                    </s>{" "}
                    {/* Strike-through original price */}
                    {`${parseInt(responseData[1]?.Price, 10)}₹`}
                    <span className="text-[14px] font-medium text-headerText">
                      /Year
                    </span>
                  </p>
                  <p className="mt-1 font-semibold text-LightGray">
                    Up to {`${responseData[1]?.User_Count}`} Users
                  </p>
                  <button className="px-6 py-2 mt-4 font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText"
                    onClick={handleStartFreeTrialId4}
                  >
                    Start Free Trial
                  </button>
                </th>
                <th className="p-4 border-[#54F59A] text-center">
                  <p className="text-lg font-medium text-GreenText">
                    ESSENTIAL+
                  </p>
                  <p className="text-headerText text-[39px] font-bold">
                    <s className="text-LightGray text-[24px] font-medium mr-2">
                    {`${responseData[2]?.Original_Price}₹`}
                    </s>{" "}
                    {/* Strike-through original price */}
                    {`${parseInt(responseData[2]?.Price, 10)}₹`}
                    <span className="text-[14px] font-medium text-headerText">
                      /Year
                    </span>
                  </p>
                  <p className="mt-1 font-semibold text-LightGray">
                    Up to {`${responseData[2]?.User_Count}`} Users
                  </p>
                  <button className="px-6 py-2 mt-4 font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText"
                    onClick={handleStartFreeTrialId4}
                  >
                    Start Free Trial
                  </button>
                </th>
                <th className="p-4 border-[#54F59A] text-center">
                  <p className="text-lg font-medium text-GreenText">
                    COMPREHENSIVE
                  </p>
                  <p className="text-headerText text-[39px] font-bold">
                    <s className="text-LightGray text-[24px] font-medium mr-2">
                    {`${responseData[3]?.Original_Price}₹`}
                    </s>{" "}
                    {/* Strike-through original price */}
                    {`${parseInt(responseData[3]?.Price, 10)}₹`}
                    <span className="text-[14px] font-medium text-headerText">
                      /Year
                    </span>
                  </p>
                  <p className="mt-1 font-semibold text-LightGray">
                    Up to {`${responseData[3]?.User_Count}`} Users
                  </p>
                  <button className="px-6 py-2 mt-4 font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText"
                    onClick={handleStartFreeTrialId4}
                  >
                    Start Free Trial
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#00AA4A" }}>
                {" "}
                {/* Black background color for the row */}
                <td
                  className="p-4 text-center" // Centering text
                >
                  <button className="mx-auto px-4  text-[16px] font-semibold text-white rounded-[4px] ">
                    OFFER
                  </button>
                </td>
                <td
                  className="p-4 text-center" // Centering text
                  style={{
                    borderTop: "1px solid #54F59A",
                  }}
                >
                  <p className="mx-auto px-4 text-[16px]  font-semibold text-white rounded-[4px] ">
                  {responseData[0]?.Offer === null ? 'Free' : `${responseData[0]?.Offer}% Off`}
                  </p>
                </td>
                <td
                  className="p-4 text-center" // Centering text
                  style={{
                    borderTop: "1px solid #54F59A",
                  }}
                >
                  <p className="mx-auto px-4  text-[16px] font-semibold text-white rounded-[4px] ">
                  {`${responseData[1]?.Offer}% Off`}
                  </p>
                </td>
                <td
                  className="p-4 text-center" // Centering text
                  style={{
                    borderTop: "1px solid #54F59A",
                  }}
                >
                  <p className="mx-auto px-4 text-[16px] font-semibold text-white rounded-[4px] ">
                  {`${responseData[2]?.Offer}% Off`}
                  </p>
                </td>
                <td
                  className="p-4 text-center" // Centering text
                  style={{
                    borderTop: "1px solid #54F59A",
                  }}
                >
                  <p className="mx-auto px-4 text-[16px] font-semibold text-white rounded-[4px] ">
                  {`${responseData[3]?.Offer}% Off`}
                  </p>
                </td>
              </tr>

              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Web portal Access
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Web_Portal_Access === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Web_Portal_Access === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Web_Portal_Access === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Web_Portal_Access === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Mobile App
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Mobile_App === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Mobile_App === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Mobile_App === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Mobile_App === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  GPS Attendance
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.GPS_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.GPS_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.GPS_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.GPS_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Locations
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  <p className="text-center">{`${responseData[0]?.Locations}`} Location</p>
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  <p className="text-center">{`${responseData[1]?.Locations}`} Locations</p>
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  <p className="text-center">{`${responseData[2]?.Locations}`} Locations</p>
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <p className="text-center">{`${responseData[3]?.Locations}`} Locations</p> */}
                  <p className="text-center">Unlimited Locations</p>
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Payroll Process
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Payroll_Process === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Payroll_Process === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Payroll_Process === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Payroll_Process === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Shifts
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Shifts === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Shifts === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Shifts === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Shifts === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Intuitive Calendar
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Intuitive_Calendar === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Intuitive_Calendar === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Intuitive_Calendar === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Intuitive_Calendar === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Selfie Attendance
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Selfie_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Selfie_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Selfie_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Selfie_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Wi-Fi and QR Attendance
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.WiFi_and_QR_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.WiFi_and_QR_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.WiFi_and_QR_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.WiFi_and_QR_Attendance === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Live Location
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Live_Location === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Live_Location === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Live_Location === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Live_Location === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Travel History
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Travel_History === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Travel_History === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Travel_History === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Travel_History === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Announcements
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Announcements === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Announcements === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Announcements === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Announcements === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Salary Advances
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Salary_Advances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Salary_Advances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Salary_Advances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Salary_Advances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Expense Claims
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Expense_Claims === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Expense_Claims === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Expense_Claims === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Expense_Claims === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Employee Verification
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Employee_Verification === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Employee_Verification === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Employee_Verification === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Employee_Verification === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Employee Documents
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Employee_Documents === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Employee_Documents === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Employee_Documents === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Employee_Documents === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Multiple Leave Policies
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Multiple_Leave_Policies === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Multiple_Leave_Policies === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Multiple_Leave_Policies === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Multiple_Leave_Policies === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Multiple Attendance Polices
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Multiple_Attendance_Polices === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Multiple_Attendance_Polices === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Multiple_Attendance_Polices === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Multiple_Attendance_Polices === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Tax Compliances
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Tax_Compliances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Tax_Compliances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Tax_Compliances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Tax_Compliances === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Approval Processes
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Approval_Processes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Approval_Processes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Approval_Processes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Approval_Processes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Kiosk Face Detection Login
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Kiosk_Face_Detection_Login === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Kiosk_Face_Detection_Login === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Kiosk_Face_Detection_Login === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Kiosk_Face_Detection_Login === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Email Integration
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Email_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Email_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Email_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Email_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  SMS Integration
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.SMS_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.SMS_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.SMS_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.SMS_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Whatsapp Integration
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Whatsapp_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Whatsapp_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Whatsapp_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Whatsapp_Integration === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>

              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Customize Themes
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Customize_Themes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Customize_Themes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Customize_Themes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Customize_Themes === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Projects
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Projects === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Projects === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Projects === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Projects === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                    borderRight: "1px solid #54F59A",
                  }}
                >
                  Chats
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[0]?.Chats === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[1]?.Chats === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {/* <img src={Tick} alt="" className="mx-auto" /> */}
                  {responseData[2]?.Chats === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
                <td
                  className="p-4"
                  style={{
                    borderTop: "1px solid #54F59A", // Adjust border width, style, and color as needed
                  }}
                >
                  {responseData[3]?.Chats === 1 ? (
                    <img src={Tick} alt="" className="mx-auto" />
                  ) : (
                    <p className="text-center"> - </p>
                  )}
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </section>
    </div>

      {/* Mobile View code */}
      <section className="mt-10 px-4 py-6 bg-Green subscriptionMobile overflow-x-auto text-center whitespace-nowrap">
        {/* <div className="bg-GreenLight rounded-lg p-1 ">
            <button
              onClick={() => setActive("YEAR")}
              className={`px-4 py-2 rounded-lg border-2 text-[20px] font-medium  ${
                active === "YEAR"
                  ? "bg-headerText text-white border-headerText"
                  : "text-headerText border-none"
              }`}
            >
              YEAR
            </button>
    <div className="flex justify-center">
  {/* Dropdown to select a plan */}
        <div className="flex justify-center">
          <div>
            <select
              onChange={handlePlanChange}
              className="px-4 py-2 rounded-lg border-2 text-[14px] font-medium text-center"
              value={selectedPlan}
            >
              <option value="STARTER">STARTER</option>
              <option value="ESSENTIAL">ESSENTIAL</option>
              <option value="ESSENTIAL+">ESSENTIAL+</option>
              <option value="COMPREHENSIVE">COMPREHENSIVE</option>
            </select>
          </div>
        </div>

        {/* Conditionally render selected plan details */}
        {selectedPlan === "STARTER" && (
          <div className="plan-details flex flex-col justify-center items-center text-center">
            <div>
              <p className="text-[38px] font-semibold text-GreenText font-urbanist mb-2">
                STARTER
              </p>

              <div className="flex flex-col items-center space-y-1 mb-2">
                <div className="flex items-center space-x-1">
                  <p className="text-[30px] font-semibold">Free</p>
                  <p className="text-[16px] font-medium text-[#262626]">
                    {/* /Lifetime */}
                  </p>
                </div>
                <p className="mt-1 text-[20px] font-semibold text-LightGray">
                  Up to 5 Users
                </p>
              </div>

              <div className="flex flex-col items-center space-y-4">
                <a href="https://signupdev.tazk.in/signUpVerification?type=Payroll&id=1">
                  <button className="px-4 py-2 text-[20px] text-nowrap font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText">
                    Register Now
                  </button>
                </a>

                <button className="px-4 py-2 text-[16px] font-semibold text-white bg-blackGray rounded-[4px] hover:bg-GreenText">
                  Free
                </button>
              </div>

              <table className="w-full mt-6">
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Web portal Access</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Mobile App</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">GPS Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Locations</td>
                  <td>
                    <p className="mx-auto" /> 1 location
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Payroll Process</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Shifts</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Intuitive Calendar</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Selfie Attendance</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Wi-Fi and QR Attendance</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Live Location</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Travel History</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Announcements</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Salary Advances</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Expense Claims</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Verification</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Documents</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Multiple Leave Policies</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">
                    Multiple Attendance Policies
                  </td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Tax Compliances</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Approval Processes</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Email Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">SMS Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Whatsapp Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Customize Themes</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Projects</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Chats</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Kiosk Face Detection Login</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
        {selectedPlan === "ESSENTIAL" && (
          <div className="plan-details flex flex-col justify-center items-center text-center">
            <div>
              <p className="text-[38px] font-semibold text-GreenText font-urbanist mb-2">
                ESSENTIAL
              </p>

              <div className="flex flex-col items-center space-y-1 mb-2">
                <div className="flex items-center space-x-1">
                  <s className="text-LightGray text-[24px] font-medium mr-2">
                    3000₹
                  </s>{" "}<br/>
                  {/* Strike-through original price */}
                  <p className="text-[30px] font-semibold">2600₹</p>
                  <p className="text-[16px] font-medium text-[#262626]">
                    /Year
                  </p>
                </div>
                <p className="mt-1 text-[20px] font-semibold text-LightGray">
                  Up to 10 Users
                </p>
              </div>

              <div className="flex flex-col items-center space-y-4">
                <a href="https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4">
                  <button className="px-4 py-2 text-[20px] text-nowrap font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText">
                    Start Free Trail
                  </button>
                </a>
                <button className="px-4 py-2 text-[16px] font-semibold text-white bg-blackGray rounded-[4px] hover:bg-GreenText">
                  20% Off
                </button>
              </div>
              <table className="w-full mt-6">
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Web portal Access</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Mobile App</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">GPS Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Locations</td>
                  <td>
                    <p className="mx-auto" />3 location
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Payroll Process</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Shifts</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Intuitive Calendar</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Selfie Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Wi-Fi and QR Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Live Location</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Travel History</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Announcements</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Salary Advances</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Expense Claims</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Verification</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Documents</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Multiple Leave Policies</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">
                    Multiple Attendance Policies
                  </td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Tax Compliances</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Approval Processes</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Email Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">SMS Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Whatsapp Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Customize Themes</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Projects</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Chats</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Kiosk Face Detection Login</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
        {selectedPlan === "ESSENTIAL+" && (
          <div className="plan-details flex flex-col justify-center items-center text-center">
            <div>
              <p className="text-[38px] font-semibold text-GreenText font-urbanist mb-2">
                ESSENTIAL+
              </p>

              <div className="flex flex-col items-center space-y-1 mb-2">
                <div className="flex items-center space-x-1">
                  <s className="text-LightGray text-[24px] font-medium mr-2">
                    9000₹
                  </s>{" "}<br/>
                  {/* Strike-through original price */}
                  <p className="text-[30px] font-semibold">6300₹</p>
                  <p className="text-[16px] font-medium text-[#262626]">
                    /Year
                  </p>
                </div>
                <p className="mt-1 text-[20px] font-semibold text-LightGray">
                  Up to 50 Users
                </p>
              </div>

              <div className="flex flex-col items-center space-y-4">
                <a href="https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4">
                  <button className="px-4 py-2 text-[20px] text-nowrap font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText">
                    Start Free Trail
                  </button>
                </a>
                <button className="px-4 py-2 text-[16px] font-semibold text-white bg-blackGray rounded-[4px] hover:bg-GreenText">
                  30% Off
                </button>
              </div>
              <table className="w-full mt-6">
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Web portal Access</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Mobile App</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">GPS Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Locations</td>
                  <td>
                    <p className="mx-auto" /> 5 location
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Payroll Process</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Shifts</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Intuitive Calendar</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Selfie Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Wi-Fi and QR Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Live Location</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Travel History</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Announcements</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Salary Advances</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Expense Claims</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Verification</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Documents</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Multiple Leave Policies</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">
                    Multiple Attendance Policies
                  </td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Tax Compliances</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Approval Processes</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Email Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">SMS Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Whatsapp Integration</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Customize Themes</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Projects</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Chats</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Kiosk Face Detection Login</td>
                  <td>
                    <p className="text-center"> - </p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
        {selectedPlan === "COMPREHENSIVE" && (
          <div className="plan-details flex flex-col justify-center items-center text-center">
            <div>
              <p className="text-[38px] font-semibold text-GreenText font-urbanist mb-2">
                COMPREHENSIVE
              </p>

              <div className="flex flex-col items-center space-y-1 mb-2">
                <div className="flex items-center space-x-1">
                  <s className="text-LightGray text-[24px] font-medium mr-2">
                    20000₹
                  </s>{" "}<br/>
                  {/* Strike-through original price */}
                  <p className="text-[30px] font-semibold">12000₹</p>
                  <p className="text-[16px] font-medium text-[#262626]">
                    /Year
                  </p>
                </div>
                <p className="mt-1 text-[20px] font-semibold text-LightGray">
                  Up to 100 Users
                </p>
              </div>

              <div className="flex flex-col items-center space-y-4">
                <a href="https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4">
                  <button className="px-4 py-2 text-[20px] text-nowrap font-semibold border-[1px] border-headerText rounded-[4px] hover:text-white hover:bg-GreenText">
                    Start Free Trail
                  </button>
                </a>
                <button className="px-4 py-2 text-[16px] font-semibold text-white bg-blackGray rounded-[4px] hover:bg-GreenText">
                  40% Off
                </button>
              </div>
              <table className="w-full mt-6">
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Web portal Access</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Mobile App</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">GPS Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Locations</td>
                  <td>
                    <p className="mx-auto" /> unlimited
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Payroll Process</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Shifts</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className=" text-left py-3">Intuitive Calendar</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Selfie Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Wi-Fi and QR Attendance</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Live Location</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Travel History</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Announcements</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Salary Advances</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Expense Claims</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Verification</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Employee Documents</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Multiple Leave Policies</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">
                    Multiple Attendance Policies
                  </td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Tax Compliances</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Approval Processes</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Email Integration</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">SMS Integration</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Whatsapp Integration</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Customize Themes</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Projects</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Chats</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
                <tr className="border-b-[1px] border-[#54F59A]">
                  <td className="text-left py-3">Kiosk Face Detection Login</td>
                  <td>
                    <img src={Tick} alt="" className="mx-auto" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Subscription;
