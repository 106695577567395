import React from "react";

function Fullwidthtitle({ title, spantitle, title2, paragraph, PrivacyImg, backgroundColor, paddingBottom, font }) {
  return (
    <section className="sm:pb-12 pt-6 md:py-12  px-8" style={{backgroundColor, fontFamily:font}}>
      <div className="relative z-0 items-center justify-center h-full container m-auto">
        <div class="perspective-container">
          <img
            src={PrivacyImg}
            alt="PrivacyImg"
            className="object-cover text-center m-auto mb-12 animated-image"
          />
        </div>

        <h4 className="text-black font-quicksand text-2xl md:text-5xl text-center font-bold md:font-medium uppercase leading-[54px] md:leading-[68px]">
          {title}&nbsp;
          <span className="font-medium  bg-headerText rounded-2xl md:rounded-full text-white py-2 md:py-1 px-4 md:px-4">
            {spantitle}
          </span>
          &nbsp;
          {title2}
        </h4>
        <p className="text-center m-auto w-[90%] pt-4" style={{paddingBottom:paddingBottom}}>{paragraph}</p>
      </div>
    </section>
  );
}

export default Fullwidthtitle;
