// src/components/Headermain.jsx
import React, { useState, useEffect } from "react";
import retaillogo from "../images/retail-chain-header.svg";
import { Link } from "react-router-dom";

const RetailChainHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`sticky top-0 z-20 transition duration-300 ease-in-out ${
        isSticky ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 md:px-8 py-4 z-10 flex justify-between items-center relative">
        <Link to="/">
          <img src={retaillogo} alt="Tazk" className="w-4/5 md:w-auto" />
        </Link>
        <div className="gap-2 flex">
          <button className="hidden md:block px-4 py-2 text-paragraphText rounded-full border border-paragraphText font-medium">
            SIGN-IN
          </button>
          <button className="px-4 py-2 bg-drkYellow text-141414 rounded-full border border-Yellow font-medium	">
            SIGN-UP
          </button>
        </div>
      </div>
    </header>
  );
};

export default RetailChainHeader;
