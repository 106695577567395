import React, { useEffect, useRef, useState } from "react";

// Components
import TestimonialSlider from "../components/RetailshopSections/TestimonialSlider";
import CTA from "../components/CTA/Cta";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";
import Fullwidthtitle from "../components/Herosections/Fullwidthtitle";
import Story from "../components/RetailshopSections/Story";
import FAQ from "../components/RetailshopSections/FAQpos";
import FAQmobiles from "../components/RetailshopSections/FAQmobile";
import Why from "../components/RetailshopSections/Whychooseus";
import OrderLine from "../components/RetailshopSections/OrderLine";
import Subscription from "../components/RetailshopSections/Subscription";
import Business from "../components/RetailshopSections/Business";
import Animationgrid from "../components/RetailshopSections/Animationgrid";
import Animationslider from "../components/RetailshopSections/Animationslider"
import Table from "../components/RetailshopSections/Table"
import Trial from "../components/RetailshopSections/Trial"
import Grid from "../components/RetailshopSections/Grid"
import Image  from "../components/RetailshopSections/Image";
import SellBetter from "../components/RetailshopSections/SellBetter";
import Grid2 from "../components/RetailshopSections/Grid2";
import Qrcode from "../components/RetailshopSections/Qrcode";
// Images
import BG from "../images/cta-bg.png";
import Privacy from "../images/privacy.svg";
import Producticon from "../images/product-icon.svg";
import ProductTour from "../images/ProductTour.svg";
import CTABG from "../images/Flow1.json";
import RetailBg from "../images/RetailBg.svg";
import received from "../images/Received.svg"
import sell1 from "../images/sell_white.svg";
import sell2 from "../images/sell_black.svg";
import { ReactComponent as CircleNextArrow } from "../images/arrow-circle-right.svg";
import { ReactComponent as CirclePrevArrow } from "../images/arrow-circle-left.svg";

const Pointofsale = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [showIcons, setShowIcons] = useState(false);
  const featuresRef = useRef(null);
  const isDesktop1 = useMediaQuery({ minWidth: 1152 });

  
  // Props content
  const testimonials = [
    {
      quote:
       "\"A fantastic experience from start to finish. The attention to detail and commitment to quality is unmatched.\"",
       author: "Arjun S",
    },
    {
      quote:
       "\"Reliable, efficient, and always ready to help. I couldn't ask for a better partner for our business needs.\"",
       author: "Meera B",
    },
    {
      quote:
      "\"Exceptional service with a personal touch. The team went above and beyond to ensure our satisfaction.\"",
      author: "Sanjay R",
    },
    // Add more testimonials as needed
  ];
  const items = [
    {
      tourheader: "Modern Dashboard ",
      tourcontent: "Customizable dashboard with all necessary cards and charts like customer growth, expenses , sales comparison, profit, payable receivable, ROI and more ",
    },
    {
      tourheader: "Comprehensive Inventory Management",
      tourcontent: "Take full control of your inventory with stock level reminders, brand- and vendor-specific summaries, location-based tracking, lot management, and easy stock returns. A mobile app enables daily stock checks and seamless transfers between branches, streamlining operations across all locations.",
    },
    {
      tourheader: "Daily Store Report for Owner ",
      tourcontent: "At day-end, the store manager sends a comprehensive report of daily sales and transactions to the owner, ensuring full transparency and enabling remote business monitoring.",
    },
    {
      tourheader: "Daily Profit Tracking",
      tourcontent: "As a business owner, you deserve quick access to your daily profit insights. Our charts and reports simplify this, providing easy-to-read, up-to-date profit information every day.",
    },
    {
      tourheader: "Vendor Scheme Receivables ",
      tourcontent: "Calculating and adjusting scheme receivables in vendor payments can be challenging for shop owners. Our dedicated dashboard simplifies this process, helping you monitor sales targets to maximize scheme benefits. Simply enter the vendor-issued schemes at the beginning of each month, and the dashboard will track your progress toward sales targets. Upon payment, scheme receivables will be automatically adjusted, ensuring accurate and efficient vendor payments.",
    },
    {
      tourheader: "Customer and Vendor Pricelist ",
      tourcontent: "A dedicated pricelist system tailored for both customers and vendors simplifies price tracking and management. This feature enables easy access to current pricing for quick reference and supports applying discounts directly to customer purchases. It streamlines the process of managing price adjustments.",
    },
  ];
  
  const tourItems = [
    {
      tourheader:"Lorem Ipsum is simply dummy text ",
      touricon: Producticon,
      subtitle: "Strengthen your business with our high-quality Point of Sale solution.",
      tourcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trail" },
        { linkUrl: "#", text: "View more" },
      ],
    },
    // Add more items as needed
  ];
  const gridData = [
    {
      tourheader: "Modern Dashboard ",
      tourcontent: "Get benefited from GrowRetail and Locsto - Sync your inventory in just one click to maximize reach and grow your business effortlessly.",
    },
    {
      tourheader: "Comprehensive Inventory Management",
      tourcontent: "GrowRetail – Share and Receive Stock with nearby retail stores.",
    },
    {
      tourheader: "Daily Store Report for Owner ",
      tourcontent: "Never Say ‘Out of Stock’ Again!",
    },
    {
      tourheader: "Daily Profit Tracking",
      tourcontent: "When customers visit your store, make sure they never hear `No Stock.` With the GrowRetail App, you can check stock availability at nearby stores if an item is out of stock. Here’s how it works:",
    },
    {
      tourheader: "Vendor Scheme Receivables ",
      tourcontent: "If a customer’s requested product isn’t available, simply check the GrowRetail app for stock at the closest store and send a notification instantly. Nearby shops respond within minutes to confirm availability. You can then pick up the item and delight your customer by delivering exactly what they want!",
    },
    {
      tourheader: "Customer and Vendor Pricelist ",
      tourcontent: "Plus, you can share your products, offers, and store details on the GrowRetail app, driving local customers to shop with you instead of online. ",
    },
  ];
  
  const tourData = [
    {
      tourheader:"Lorem Ipsum is simply dummy text ",
      touricon: Producticon,
      subtitle: "Strengthen your business with our high-quality Point of Sale solution.",
      tourcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trail" },
        { linkUrl: "#", text: "View more" },
      ],
    },
    // Add more items as needed
  ];

  const faqData = [
    {
      question: "01. How does free trial work?",
      answer:
        "With Tazk Payroll's free trial, you get full access to all features for 14 days, allowing you to explore the product and evaluate its benefits for your business. No credit card is needed to start the trial.",
    },
    {
      question: "02. Is there any cancellation fee?",
      answer:
        "There is no cancellation fee on any of our plans. However if you have availed an annual subscription plan the remaining credit will not be refunded if you discontinue Tazk Payroll service during the validity of the plan.",
    },
    {
      question: "03. What happens if there are changes in staff?",
      answer:
        "You can add or remove users at any time according to your needs. When you add new users, they will be charged on a pro-rata basis, meaning you'll only pay for the portion of the billing cycle during which they are active. Similarly, if you remove users, you'll receive a credit for the unused portion of their subscription. This flexible approach ensures you only pay for the user access you actually use.",
    },
    {
      question: "04. How is an active user defined?",
      answer:
        "An active user is anyone who has access to the application or holds a user account within it.",
    },
    {
      question: "05.What types of payment do you accept?",
      answer:
        "Tazk offers a range of flexible payment options, including online payments via Credit Card, Debit Card, Net Banking and UPI. For offline payment inquiries, please contact us at sales@tazk.com.",
    },
    {
      question: "06. How secure is the safety of my data?",
      answer:
        "We have implemented stringent security protocols to ensure the integrity and confidentiality of your data. Physical server access is fortified through multi-tiered access controls. Our sophisticated multi-tenancy architecture rigorously isolates customer data, preventing any possibility of cross-tenant data exposure. We enforce rigorous user authentication mechanisms and stringent password policies to safeguard your information. Furthermore, all data transmissions between your browser and our servers are secured using industry-leading 256-bit SSL encryption, providing unmatched protection",
    },
    {
      question: "07.  Who has ownership of my data?",
      answer:
        "Your data is entirely yours, with full ownership and control. You have seamless access to your data at any time with a single click. Additionally, you can export all your information at your convenience, regardless of your subscription plan.",
    },
    {
      question: "08. What happens when my free trial period ends?",
      answer:
        "When you sign up for the free trial, you'll get full access to Tazk Payroll for 14 days at no cost. To keep using the service after the trial, choose a plan and pay the subscription charges.",
    },
  ];

  const faqData1 = [
    {
      question: "01. How does free trial work?",
      answer:
        "With Tazk Payroll's free trial, you get full access to all features for 14 days, allowing you to explore the product and evaluate its benefits for your business. No credit card is needed to start the trial.",
    },
    {
      question: "02. Is there any cancellation fee?",
      answer:
        "There is no cancellation fee on any of our plans. However if you have availed an annual subscription plan the remaining credit will not be refunded if you discontinue Tazk Payroll service during the validity of the plan.",
    },
    {
      question: "03. What happens if there are changes in staff?",
      answer:
        "You can add or remove users at any time according to your needs. When you add new users, they will be charged on a pro-rata basis, meaning you'll only pay for the portion of the billing cycle during which they are active. Similarly, if you remove users, you'll receive a credit for the unused portion of their subscription. This flexible approach ensures you only pay for the user access you actually use.",
    },
    {
      question: "04. How is an active user defined?",
      answer:
        "An active user is anyone who has access to the application or holds a user account within it.",
    },
    {
      question: "05.What types of payment do you accept?",
      answer:
        "Tazk offers a range of flexible payment options, including online payments via Credit Card, Debit Card, Net Banking and UPI. For offline payment inquiries, please contact us at sales@tazk.com.",
    },
    {
      question: "06. How secure is the safety of my data?",
      answer:
        "We have implemented stringent security protocols to ensure the integrity and confidentiality of your data. Physical server access is fortified through multi-tiered access controls. Our sophisticated multi-tenancy architecture rigorously isolates customer data, preventing any possibility of cross-tenant data exposure. We enforce rigorous user authentication mechanisms and stringent password policies to safeguard your information. Furthermore, all data transmissions between your browser and our servers are secured using industry-leading 256-bit SSL encryption, providing unmatched protection",
    },
    {
      question: "07.  Who has ownership of my data?",
      answer:
        "Your data is entirely yours, with full ownership and control. You have seamless access to your data at any time with a single click. Additionally, you can export all your information at your convenience, regardless of your subscription plan.",
    },
    {
      question: "08. What happens when my free trial period ends?",
      answer:
        "When you sign up for the free trial, you'll get full access to Tazk Payroll for 14 days at no cost. To keep using the service after the trial, choose a plan and pay the subscription charges.",
    },
  ];
  // const whycarddata = [
  //   {
  //     cardtitle: "LOREM IPSUM",
  //     cardcontent:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
  //     dotColor: "#B0DAFA",
  //     borderColor: "#007CDA",
  //   },
  //   {
  //     cardtitle: "LOREM IPSUM",
  //     cardcontent:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
  //     dotColor: "#FFF6C3",
  //     borderColor: "#FEDB03",
  //   },
  //   {
  //     cardtitle: "LOREM IPSUM",
  //     cardcontent:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
  //     dotColor: "#B0FAD0",
  //     borderColor: "#00AA4A",
  //   },
  //   {
  //     cardtitle: "LOREM IPSUM",
  //     cardcontent:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the",
  //     dotColor: "#FFC3B0",
  //     borderColor: "#E83800",
  //   },
  // ];

  const topHeaderItems = [
    {
      buttons: [{ linkUrl: "#", text: "Start a free trail" }],
    },
  ];

  return (
    <>
      <Helmet>
        <title>PointOfSale | The ERP Solution</title>
        <meta
          name="description"
          content="This is the SEO description for Tazk."
        />
        <meta name="keywords" content="landing page, seo, example" />
        <meta property="og:title" content="RetailShop | The ERP Solution" />
        <meta
          property="og:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        <meta property="og:image" content="../images/favicon.ico" />
        <meta property="og:url" content="https://tazk.in/RetailShop" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="RetailShop | The ERP Solution" />
        <meta
          name="twitter:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
      </Helmet>

      {/* <OrderLine
        title="Lorem Ipsum is simply dummy text."
        items={topHeaderItems}
      /> */}
      <Trial 
    title="Grow Your Business with Our Best POS Software"
    subtitle="Simple and Effective Billing Software, Perfectly Tailored for Indian Retail Stores."
    items={topHeaderItems}
    backgroundColor="#000000"
    linkUrl="https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4"
    />
    <Image 
    cardtitle="Your Smart Sales Assistant – Simplifying Sales and Accelerating Your Success."
    cardcontent="Tazk POS simplifies retail management. Drive sales, optimize operations, and step into digital transformation—all just a click away with the Future of Retail POS. "
    cardcontent3="Join now to enjoy the difference."
     cardcontent2="Manage your business effortlessly with an easy interface and strong features like Easy Physical Stock Check, Payment Tracking and Reminders, Vendor Schemes, Repair Services, Accounting and GST compliance. "
    Bg={RetailBg}
    BG1={received}
    />
      {/* <Why title="Why Choose Us?" whycard={whycarddata} /> */}

      {/* <Business title="WHAT WE’RE DOING FOR YOUR BUSSINESS" items={tourItems} /> */}
      <Grid title="Discover Features of Tazk POS" subtitle="Strengthen your business with our high-quality Point of Sale solution." items={ items} />

      {/* <Fullwidthtitle
        title="Your"
        spantitle="Privacy"
        PrivacyImg={Privacy}
        title2="Is Our 
        Responsibility"
        paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen ."
      /> */}

      {/* <Story title="Tour Title" items={tourItems} /> */}
      <Animationgrid
      title="Lorem Ipsum is simply dummy text of the printing."
      subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      backgroundColor="black"
      // contentTitle="Lorem Ipsum is simply dummy text "
      // contentSubtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      header1="Approvals"
      header2="Quotations"
      header3="Branch Stock Transfer "
      header4="Physical Stock Check "
      header5="Payments"
      header6="Easy Returns"
      />
      {/* <Animationslider
      title="Free add – on"
      subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      backgroundColor="black"
      
      /> */}
      {/* <SellBetter title= "Lorem Ipsum is simply dummy text of the printing."
      subtitle=
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      backgroundColor="#FBEB8A"
      content="Sell Better"
      sell1={sell1}
      sell2={sell2}
      font="onest"
      text="#FFFFFF"
      text1="#000000"/> */}
      <Fullwidthtitle
        title="Your"
        font="onest"
        backgroundColor="#FFFFFF"
        spantitle="Privacy"
        PrivacyImg={Privacy}
        title2="Is Our 
        Responsibility"
        paragraph="Your privacy is our top priority. We are committed to safeguarding your personal information with the highest level of security and transparency, ensuring your data remains safe and confidential."
      paddingBottom="50px"
      />
      {/* <Subscription /> */}
       <Table />
       {/* <Grid2 title="GrowRetail Retailer App and LocSto Customer app and website." items={tourData,gridData} /> */}
       <div className="relative">
        {/* Floating Circle Chat Icon */}
        <div className="fixed bottom-5 right-5 z-50 flex flex-col items-center">
          {" "}
          {/* Centered the icons */}
          {/* Display Icons above the circle icon when clicked */}
          {showIcons && (
            <div className="flex flex-col items-center mb-2 space-y-2">
              {/* WhatsApp Icon */}
              <div className="relative group flex flex-col items-center">
                <a
                  href="https://wa.me/9500000404"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "#25D366" }} // WhatsApp green background
                  >
                    <i className="fab fa-whatsapp text-white text-2xl"></i>{" "}
                    {/* White WhatsApp icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  WhatsApp
                </span>
              </div>

              {/* Phone Icon */}
              <div className="relative group flex flex-col items-center">
                <a href="tel:+919500000404" className="flex items-center">
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "#25D366" }} // Phone green background
                  >
                    <i className="fas fa-phone text-white text-2xl"></i>{" "}
                    {/* White Phone icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  Call Us
                </span>
              </div>

              {/* Email Icon */}
              <div className="relative group flex flex-col items-center">
                <a href="mailto:mail@tazk.in" className="flex items-center">
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "rgb(0, 123, 255)" }} // Blue background for Email
                  >
                    <i className="fas fa-envelope text-white text-2xl"></i>{" "}
                    {/* White Email icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  Email Us
                </span>
              </div>
            </div>
          )}
          {/* Main Circle Chat Icon */}
          <div
            onClick={() => setShowIcons(!showIcons)} // Toggle icon visibility
            className="w-[54px] h-[54px] rounded-full border-black border-[2px] flex items-center justify-center shadow-lg  cursor-pointer"
            style={{ backgroundColor: "#FBEB8A" }} // Green background for the chat icon
          >
            {showIcons ? (
              <i className="fas fa-times text-white text-xl"></i> // Close icon when icons are visible
            ) : (
              <i className="fas fa-comments text-black text-xl"></i> // Chat icon when icons are hidden
            )}
          </div>
        </div>
      </div>
      {isDesktop ? (
        <div className="pos-faq-circle">
        <FAQ 
          faqData={faqData} 
          backgroundColor="#FBEB8A"
          font="onest"
          text="#000000"
          border="1px solid #000000"
          fontsize="25px"
        />
      </div>
      
      ) : (
        <div className="pos-faq-circle">
        <FAQmobiles faqData={faqData1}
        font="onest"
        text="#000000"
        backgroundColor= "#FBEB8A"
        border="1px solid #000000"/>
        </div>
      )}
   
    <div className="pos-white-star py-20 bg-white">
      <TestimonialSlider
        testimonials={testimonials}
        slidesToShowDesktop={1}
        slidesToShowMobile={1}
        backgroundColor1="#FFFFFF"
        backgroundColor="#000000"
        text="#D2D2D2"
        text1="#FFFFFF"
        font="onest"
        customNextArrow={<CircleNextArrow />}  // Passing circle arrow
        customPrevArrow={<CirclePrevArrow />}
      />
       </div>
    <Qrcode/>
    </>
  );
};

export default Pointofsale ;
