import React from "react";

function Why({ title, whyimg, whycard }) {
  return (
    <section className="py-12 bg-[#F5F5F5] px-4">
      <div className="container m-auto">
        <div className="mx-4 md:mx-8 z-10 ">
          <div className="mb-8 text-left">
            <h4 className="text-black text-2xl md:text-3xl lg:text-4xl font-medium uppercase">
              {title}
            </h4>
          </div>
          <div className="flex flex-col md:flex-row gap-8">
            <div className="w-full md:w-1/2">
              <img
                src={whyimg}
                alt="whyimg"
                className="object-cover w-full h-auto rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="mb-6">
                {whycard.map((item, index) => (
                  <div className="mb-4 md:mb-8" key={index}>
                    <p className="text-base md:text-lg">
                      <span className="text-headerText text-xl md:text-2xl font-semibold">
                        {item.cardtitle}&nbsp;
                      </span>
                      {item.cardcontent}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Why;
