// src/pages/Home.js
import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import Slider from "../components/Slider";

// Components
import LeftimageSection from "../components/Content/Leftimage";
import RightimageSection from "../components/Content/Rightimage";
import MobileRightImageSection from "../components/Content/MobileRightImageSection";
import Counter from "../components/Herosections/Counter";
import Herosection from "../components/Herosections/Herosection";
import MobileHeroSection from "../components/Herosections/MobileHeroSection";

import Fullwidthtitle from "../components/Herosections/Fullwidthtitle";
import Title from "../components/Herosections/Title";
import CTA from "../components/CTA/Cta";
import Why from "../components/Herosections/Whychooseus";
import Producttour from "../components/Herosections/Producttour";
import Mobileproducttour from "../components/Herosections/Mobileproducttour";
import TestimonialSlider from "../components/Herosections/TestimonialSlider";

// images
import Leads from "../images/leads1.svg";
import Asset from "../images/asset.svg";
import Distribution from "../images/distribution.svg";
import Payrol from "../images/payroll (2).png";
import Retail from "../images/retail-chain.svg";
import Retailshop from "../images/retail-shop.svg";
import Services from "../images/services1.svg";
import CenterImage from "../images/center-image.svg";
import LeftImg from "../images/left-img1.svg";
import LeftImg1 from "../images/left-img2.svg";
import RightImg from "../images/right-image.svg";
import BG from "../images/cta-bg.png";
import ProductTour from "../images/ProductTour.svg";
import Producttourservices from "../images/Producttourservices.svg";
import Privacy from "../images/privacy.svg";
import why from "../images/why.svg";
import CTABG from "../images/Flow1.json";
import downarrow from "../images/circle-arrow-down.svg";

const Home = () => {
  // Responsive based component load
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isDesktop1 = useMediaQuery({ minWidth: 1152 });
  const [showIcons, setShowIcons] = useState(false);
  const productTourRef = useRef(null);

  const scrollToProductTour = () => {
    if (productTourRef.current) {
      productTourRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSlider = () => {
    const sliderSection = document.getElementById("section-slider");
    if (sliderSection) {
      sliderSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to the top of the page on reload
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Props content

  const descriptionText1 = `We’re dedicated to building enduring relationships and ensuring long-term success for our clients. Our commitment extends beyond the immediate, focusing on sustainable growth and lasting partnerships.`;

  const descriptionText2 = `By prioritizing R&D, we drive innovation and stay ahead of industry trends. Our continuous investment in emerging technologies ensures we deliver cutting-edge solutions tailored to your needs.`;

  const descriptionText3 = `Our clients are at the heart of everything we do. We tailor our solutions to your specific needs and strive to exceed your expectations with exceptional service and proactive support.`;

  const countersData = [
    {
      value: "50+",
      label: "Products",
      bgColor: "#FFFDF2",
      borderColor: "#FFF093",
    },
    {
      value: "15+ ",
      label: "Countries",
      bgColor: "#F2FEF7",
      borderColor: "#B0FAD0",
    },
    {
      value: "16+",
      label: "Years Experience",
      bgColor: "#F2F9FE",
      borderColor: "#B0DAFA",
    },
    {
      value: "1000+ ",
      label: "Happy Customers",
      bgColor: "#FFF5F2",
      borderColor: "#FFC3B0",
    },
  ];

  const featuresData = [
    {
      image: Leads,
      label: "Leads",
      link: "/leads",
      position: "leads",
    },
    {
      image: Services,
      label: "Repair Services",
      link: "#",
      position: "service",
    },
    {
      image: Asset,
      label: "Asset",
      link: "#",
      position: "asset",
    },
    {
      image: Payrol,
      label: "Payroll",
      link: "/payroll",
      position: "payroll",
    },
    {
      image: Retailshop,
      label: "Point of Sale ",
      link: "/pointofsale",
      position: "shop",
    },
    {
      image: Distribution,
      label: "Sales and Distribution",
      link: "#",
      position: "chain",
    },
    // {
    //   image: Distribution,
    //   label: "Sales and Distribution",
    //   link: "#",
    //   position: "distribution",
    // },
  ];

  const whycarddata = [
    {
      cardtitle: "01.Expertise and Experience",
      cardcontent:
        "With years of experience in the application service industry, we bring a wealth of knowledge and technical expertise to the table. Our team of skilled professionals is dedicated to delivering innovative solutions that meet your unique business needs.",
    },
    {
      cardtitle: "02.Cutting-Edge Technology",
      cardcontent:
        "Our commitment to leveraging the latest technologies ensures that you receive state-of-the-art solutions. We stay ahead of industry trends to provide you with applications that are not only functional but also innovative and future-proof.",
    },
    {
      cardtitle: "03.Cost-Effective Solutions",
      cardcontent:
        "We offer competitive pricing without compromising on quality. Our solutions are designed to provide maximum value, ensuring you get the best return on your investment.",
    },
    {
      cardtitle: "04.Commitment to Security",
      cardcontent:
        "We prioritize the security of your data and applications. Our robust security measures and best practices protect your information and ensure compliance with industry standards.",
    },
  ];

  const tourItems = [
    {
      touricon: Retailshop,
      subtitle: "Point of Sale ",
      tourcontent:
        "Our Solution is specially designed with your Retail business in mind, user-friendly experience that simplifies transactions and enhances efficiency. With unique features like transferring stocks between your store, vendor schemes Dashboard, easy physical stock checks, GST compliance, repair services, scheme management pay-in/pay-out tracking, Receivable and payable management, and comprehensive inventory, accounting, payment reminders and customer data management, you gain the tools to drive your business growth. We ensure your operations run smoothly, so you can focus on delivering the best experience to your customers.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "/retailShop", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Payrol,
      subtitle: "Payroll",
      tourcontent:
        "Our trusted attendance and payroll software makes team management simple. It uses GPS-based mobile attendance for precise time tracking and offers fast payroll processing, flexible policies, and multi-shift management. With an easy interface, quick leave approvals, and smooth handling of salary advances and expense claims, our solution saves you time, minimizes errors, and gives you control from anywhere - so you can focus on growing your business.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "/payroll", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Leads,
      subtitle: "Leads",
      tourcontent:
        "Our trusted leads management software helps you capture, organize, and follow up on leads easily. With its simple design and real-time tracking, you can prioritize the best opportunities and streamline your sales efforts. Boost your conversions and grow your business with our effective leads management solution.",
      tourimage: Producttourservices,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Asset,
      subtitle: "Assets",
      tourcontent:
        "Our reliable asset management software makes it easy to track, manage, and maintain your assets. With user-friendly features and real-time updates, you can monitor asset performance, streamline maintenance, and ensure the best use of your assets. Increase efficiency and make informed decisions with our dependable asset management solution.",
      tourimage: Producttourservices,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Services,
      subtitle: "Repair Services",
      tourcontent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the ",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },

    {
      touricon: Distribution,
      subtitle: "Sales and Distribution",
      tourcontent:
        "Our comprehensive distribution app helps businesses expand. It strengthens your sales team, monitors payments, manages approvals, tracks vendor dues, and gives live delivery updates. With efficient inventory management and complete support for the distribution process, our app ensures your business thrives in today's market.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },

    // {
    //   touricon: Retail,
    //   subtitle: "Retail Chain",
    //   tourcontent:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the ",
    //   tourimage: Producttourservices,
    //   buttons: [
    //     { linkUrl: "#", text: "Start a free trial", type: "filled" },
    //     { linkUrl: "/retailchain", text: "View more", type: "bordered" },
    //   ],
    // },

    // Add more items as needed
  ];
  const tourItems1 = [
    {
      touricon: Retailshop,
      subtitle: "Point of Sale ",
      tourcontent:
        "Our leading POS software makes sales, inventory, and customer data management simple. With an easy interface and strong features - like repair services, scheme management, receivables, GST compliance, and seamless payments - it optimizes your operations and gives you real-time insights. Strengthen your business with our high-quality Point of Sale solution.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Payrol,
      subtitle: "Payroll",
      tourcontent:
        "Our trusted attendance and payroll software makes team management simple. It uses GPS-based mobile attendance for precise time tracking and offers fast payroll processing, flexible policies, and multi-shift management. With an easy interface, quick leave approvals, and smooth handling of salary advances and expense claims, our solution saves you time, minimizes errors, and gives you control from anywhere - so you can focus on growing your business.",
      tourimage: ProductTour,
      buttons: [
        {
          linkUrl:
            "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4",
          text: "Start a free trial",
          type: "filled",
        },
        { linkUrl: "/payroll", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Leads,
      subtitle: "Leads",
      tourcontent:
        "Our trusted leads management software helps you capture, organize, and follow up on leads easily. With its simple design and real-time tracking, you can prioritize the best opportunities and streamline your sales efforts. Boost your conversions and grow your business with our effective leads management solution. ",
      tourimage: Producttourservices,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Asset,
      subtitle: "Asset",
      tourcontent:
        "Our reliable asset management software makes it easy to track, manage, and maintain your assets. With user-friendly features and real-time updates, you can monitor asset performance, streamline maintenance, and ensure the best use of your assets. Increase efficiency and make informed decisions with our dependable asset management solution.",
      tourimage: Producttourservices,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },
    {
      touricon: Services,
      subtitle: "Repair Services",
      tourcontent:
        "Our repair and service tool provides comprehensive control over all aspects of your repair business with a fully integrated, end-to-end workflow. This means that from the moment a repair request is received to the final delivery of the repaired item, every step is managed within a single, unified platform. ",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },

    {
      touricon: Distribution,
      subtitle: "Sales and Distribution",
      tourcontent:
        "Our comprehensive distribution app helps businesses expand. It strengthens your sales team, monitors payments, manages approvals, tracks vendor dues, and gives live delivery updates. With efficient inventory management and complete support for the distribution process, our app ensures your business thrives in today's market.",
      tourimage: ProductTour,
      buttons: [
        { linkUrl: "#", text: "Start a free trial", type: "filled" },
        { linkUrl: "#", text: "View more", type: "bordered" },
      ],
    },

    // {
    //   touricon: Retail,
    //   subtitle: "Retail Chain",
    //   tourcontent:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the ",
    //   tourimage: Producttourservices,
    //   buttons: [
    //     { linkUrl: "#", text: "Start a free trial", type: "filled" },
    //     { linkUrl: "#", text: "View more", type: "bordered" },
    //   ],
    // },

    // Add more items as needed
  ];

  const testimonials = [
    {
      quote:
        "TAZK's expertise in IT services has been a game-changer for our business. From cloud solutions to cybersecurity, they've handled everything with precision and professionalism. We trust them completely with our IT needs.",
      author: "Karthik Subramaniam",
    },
    {
      quote:
        "TAZK is a true partner in every sense. They understand our unique needs and deliver customized IT solutions that fit perfectly with our business goals. Their team is knowledgeable, reliable, and always ready to help.",
      author: "Vishnu Prasad",
    },
    {
      quote:
        "Choosing TAZK for our IT services was the right move. Their proactive approach to IT management has not only secured our data but also improved our overall productivity. The peace of mind they provide is invaluable.",
      author: "Sneha Ramesh",
    },
    // Add more testimonials as needed
    //addd
  ];
  return (
    <>
      <Helmet>
        <title>Tazk | The ERP Solution | We elevate your business </title>
        <meta
          name="description"
          content="This is the SEO description for Tazk."
        />
        <meta name="keywords" content="landing page, seo, example" />
        <meta property="og:title" content="Tazk | The ERP Solution" />
        <meta
          property="og:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        <meta property="og:image" content="../images/favicon.ico" />
        <meta property="og:url" content="https://tazk.in/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tazk | The ERP Solution" />
        <meta
          name="twitter:description"
          content="Learn more about Tazk, the ultimate ERP solution for businesses."
        />
        {/* <meta name="twitter:image" content="/path-to-your-image.jpg" /> */}
      </Helmet>

      {isDesktop ? (
        <div className="custom-hero-height" style={{ overflow: "hidden" }}>
          <Herosection
            title={
              <>
                We Elevate Your <br />
                Business
              </>
            }
            buttonText="Take a Look"
            onClick={scrollToSlider}
            features={featuresData}
          />
        </div>
      ) : (
        <MobileHeroSection
          title={
            <>
              We Elevate Your <br />
              Business
            </>
          }
          buttonText="Take a Look"
          features={featuresData}
          onButtonClick={scrollToProductTour} // Pass the scroll function
        />
      )}

      <Counter
        title={
          <>
            We help your business grow with our reliable
            <br /> user-friendly solutions.
          </>
        }
        imageUrl={CenterImage}
        counters={countersData}
      />
      {isDesktop1 ? (
        <Slider />
      ) : (
        <Mobileproducttour
          title="Products Tour"
          items={tourItems1}
          ref={productTourRef}
        />
      )}

      {/* <Producttour title="Products Tour" items={tourItems} /> */}

      {/* {isDesktop1 ? (
       
      ) : (
        <Mobileproducttour title="Products Tour" items={tourItems1} />
      )} */}

      <Title
        title={
          <>
            Our driving force is our core
            <br /> values and principles
          </>
        }
      />

      <LeftimageSection
        leftImgSrc={LeftImg}
        title="Long-term Commitment"
        description={descriptionText1}
        bgColor="bg-[#FFF6C0]"
      />

      {isDesktop1 ? (
        <RightimageSection
          rightImgSrc={RightImg}
          title="Focus on research and development"
          description={descriptionText2}
          bgColor="bg-[#BFE1FB]"
        />
      ) : (
        <MobileRightImageSection
          rightImgSrc={RightImg}
          title="Focus on research and development"
          description={descriptionText2}
          bgColor="bg-[#BFE1FB]"
        />
      )}

      <LeftimageSection
        leftImgSrc={LeftImg1}
        title="Customer-first philosophy"
        description={descriptionText3}
        bgColor="bg-[#BFFBD9]"
      />

      <Fullwidthtitle
        title="Your"
        spantitle="Privacy"
        PrivacyImg={Privacy}
        title2={
          <>
            Is Our
            <br />
            Responsibility
          </>
        }
        paragraph="Your privacy is our top priority. We are committed to safeguarding your personal information with the highest level of security and transparency, ensuring your data remains safe and confidential."
      />
      <Why title="Why Choose Us?" whyimg={why} whycard={whycarddata} />

      <TestimonialSlider
        testimonials={testimonials}
        slidesToShowDesktop={1}
        slidesToShowMobile={1}
        backgroundColor="#FFF6C3"
      />
      <div className="relative">
        {/* Floating Circle Chat Icon */}
        <div className="fixed bottom-5 right-5 z-50 flex flex-col items-center">
          {" "}
          {/* Centered the icons */}
          {/* Display Icons above the circle icon when clicked */}
          {showIcons && (
            <div className="flex flex-col items-center mb-2 space-y-2">
              {/* WhatsApp Icon */}
              <div className="relative group flex flex-col items-center">
                <a
                  href="https://wa.me/9500000404"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "#25D366" }} // WhatsApp green background
                  >
                    <i className="fab fa-whatsapp text-white text-2xl"></i>{" "}
                    {/* White WhatsApp icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  WhatsApp
                </span>
              </div>

              {/* Phone Icon */}
              <div className="relative group flex flex-col items-center">
                <a href="tel:+919500000404" className="flex items-center">
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "#25D366" }} // Phone green background
                  >
                    <i className="fas fa-phone text-white text-2xl"></i>{" "}
                    {/* White Phone icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  Call Us
                </span>
              </div>

              {/* Email Icon */}
              <div className="relative group flex flex-col items-center">
                <a href="mailto:mail@tazk.in" className="flex items-center">
                  <div
                    className="w-[50px] h-[50px] rounded-full flex items-center justify-center"
                    style={{ backgroundColor: "rgb(0, 123, 255)" }} // Blue background for Email
                  >
                    <i className="fas fa-envelope text-white text-2xl"></i>{" "}
                    {/* White Email icon */}
                  </div>
                </a>
                <span className="absolute left-[-60px] text-black text-xs bg-white p-1 rounded shadow opacity-0 group-hover:opacity-100 transition-opacity">
                  Email Us
                </span>
              </div>
            </div>
          )}
          {/* Main Circle Chat Icon */}
          <div
            onClick={() => setShowIcons(!showIcons)} // Toggle icon visibility
            className="w-[54px] h-[54px] rounded-full flex items-center justify-center shadow-lg cursor-pointer"
            style={{ backgroundColor: "rgb(0, 170, 74)" }} // Green background for the chat icon
          >
            {showIcons ? (
              <i className="fas fa-times text-white text-xl"></i> // Close icon when icons are visible
            ) : (
              <i className="fas fa-comments text-white text-xl"></i> // Chat icon when icons are hidden
            )}
          </div>
        </div>
      </div>

      {/* <CTA
        title="Ready to do your best work?"
        subtitle="Let's get you started"
        buttonText="Start a free trial"
        linkUrl="#"
        animationData={CTABG}
        bgurl={BG}
      /> */}
    </>
  );
};

export default Home;
