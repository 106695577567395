import React, { useState, useEffect, useRef } from "react";
import retaillogo from "../images/Pos-footer-logo.svg";
import { Link } from "react-router-dom";
import getServerConfig from "../http-common";
import fb from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import x from "../images/x.svg";
import linkedin from "../images/linkdin.svg";
import sms from "../images/sms.svg";
import call from "../images/call.svg";
import add from "../images/add.svg";
import Grid from "./RetailshopSections/Grid";

const RetailShopHeader = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [isTopSectionVisible, setIsTopSectionVisible] = useState(true);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false); // Hamburger menu state
  const hamburgerMenuRef = useRef(null); // Ref to detect clicks outside

  // Handle sticky header on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Open the "Add" button section by default on page load
  useEffect(() => {
    setIsTopSectionVisible(true);
  }, []);

  // Close hamburger menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hamburgerMenuRef.current && !hamburgerMenuRef.current.contains(event.target)) {
        setIsHamburgerOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Toggle MegaMenu
  const toggleMegaMenu = () => {
    setIsMegaMenuOpen(!isMegaMenuOpen);
  };

  // Handle Add button click to toggle visibility of top section
  const handleAddButtonClick = () => {
    setIsTopSectionVisible(false); // Hide section when Add button is clicked
  };

  // Redirect functions for various links
  const handleStartFreeTrialId4 = () => {
    const redirectUrl = getServerConfig('5', 4); 
    window.open(redirectUrl, '_blank');
  };

  const features = () => {
    const redirectUrl = getServerConfig('5', 3); 
    window.open(redirectUrl, '_blank');
  };

  const linkUrl = () => {
    const redirectUrl = "https://signupdev.tazk.in/signUpVerification?type=Payroll&id=4";
    window.open(redirectUrl, '_blank');
  };

  const linkUrl2 = () => {
    const redirectUrl = "https://erp.tazk.in";
    window.open(redirectUrl, '_blank');
  };

  // Social Links Data
  const socialLinks = [
    { src: instagram, alt: "Instagram", href: "#" },
    { src: fb, alt: "Facebook", href: "#" },
    { src: x, alt: "X", href: "#" },
    { src: linkedin, alt: "LinkedIn", href: "#" },
  ];

  // Hamburger Menu toggle
  const toggleHamburger = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  // Close hamburger menu when any link inside the menu is clicked
  const handleHamburgerLinkClick = () => {
    setIsHamburgerOpen(false); // Close the menu when a link is clicked
  };

  return (
    <header className={`sticky top-0 z-20 transition duration-300 ease-in-out ${isSticky ? "bg-black shadow-md" : "bg-transparent"}`}>
      {/* Top Section with Social Links and Contact Info */}
      {isTopSectionVisible && (
        <div className="flex bg-white justify-between px-4">
          <div className="md:flex md:flex-row hidden">
            {socialLinks.map((link, index) => (
              <a key={index} href={link.href} className="flex-shrink-0">
                <img src={link.src} alt={link.alt} className="w-8 h-8" />
              </a>
            ))}
          </div>

          <div className="flex flex-row space-x-4 md:space-x-4">
            <button className="flex text-xs md:text-base items-center"><img src={sms} alt="sms" />mailto:mail@tazk.in</button>
            <button className="flex text-xs md:text-base items-center"><img src={call} alt="call" />9500000404</button>
            <button className="pl-20 md:pl-0" onClick={handleAddButtonClick}><img src={add} alt="add" /></button>
          </div>
        </div>
      )}

      {/* Main Header Section */}
      <div className="container mx-auto px-4 md:px-8 py-4 z-10 flex justify-between items-center relative">
        {/* <Link to="/"> */}
          <img src={retaillogo} alt="Tazk" className="w-1/2 md:w-auto" />
        {/* </Link> */}
        <div className="gap-2 flex">
          <button className="hidden md:block px-4 py-2 text-white font-medium">
            <a href="#features-section" className="block">Features</a>
          </button>
          <button className="hidden md:block px-4 py-2 text-white font-medium">
            <a href="#pricing-section" className="block">Pricing</a>
          </button>
          <button className="hidden md:block px-4 py-2 text-white font-medium" onClick={linkUrl2}>
            SIGN-IN
          </button>
     
          <button className="md:px-4 px-2 py-2 bg-white text-black rounded-[14px] border font-medium" onClick={linkUrl}>
            SIGN-UP
          </button>
                {/* Hamburger menu for mobile - moved to right */}
          <div className="md:hidden flex items-center ml-auto" ref={hamburgerMenuRef}>
            <button onClick={toggleHamburger} className="text-white">
              <span className="text-xl">☰</span> {/* Hamburger Icon */}
            </button>
            {isHamburgerOpen && (
              <div className="absolute top-0 right-0 bg-white w-30 px-2 rounded-lg shadow-lg z-30">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    className="flex items-center text-sm mb-2 text-black"
                    onClick={handleHamburgerLinkClick} // Close menu when a link is clicked
                  >
                    <img src={link.src} alt={link.alt} className="w-6 h-6 mr-2" />
                    {link.alt}
                  </a>
                ))}
              </div>
            )}
          </div>
          
        </div>
      </div>
    </header>
  );
};

export default RetailShopHeader;
