import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarRatings from "react-star-ratings";

import { ReactComponent as CustomNextArrow } from "../../images/arrow-right.svg";
import { ReactComponent as CustomPrevArrow } from "../../images/arrow-left.svg";

const TestimonialSlider = ({
  testimonials,
  slidesToShowDesktop,
  slidesToShowMobile,
  backgroundColor, // Add this prop
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShowDesktop,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShowMobile,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <section className="py-12 md:py-24 md:px-8 pb-16  md:pb-30" style={{ backgroundColor }}>
      <div
        className="container relative z-0 items-center justify-center h-full w-full md:w-9/12 lg:w-3/5 p-12 m-auto rounded-3xl text-left"
        // Use the backgroundColor prop here
      >
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-slide">
              <h4 className="text-black text-xl md:text-3xl text-left font-semibold mb-4">
                {testimonial.author}
              </h4>

              <p className="mt-2 mb-6">{testimonial.quote}</p>
              <StarRatings
                rating={testimonial.rating} // Assuming rating is provided in the testimonial data
                starRatedColor="black"
                numberOfStars={5}
                name="rating"
                starDimension="24px"
                starSpacing="5px"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
export default TestimonialSlider;
