import React from "react";

function Counter({ title, imageUrl, counters }) {
  return (
    <section id="next-section" className="py-8 md:py-20 bg-[#F5F5F5] relative">
      <div className="relative z-0 items-center justify-center h-full text-center px-4 md:px-8 container m-auto">
        <h4 className="text-black text-2xl md:text-3xl font-400 mb-12 leading-10">
          {title}
        </h4>
        <img
          src={imageUrl}
          alt="Center"
          className="m-auto mt-4 w-full md:w-1/2"
        />
      </div>
      <div className="mx-auto px-4 md:px-8 pt-12 z-10 container m-auto">
        <div className="flex flex-col md:flex-row gap-4 md:gap-4">
          {counters.map((counter, index) => (
            <div key={index} className="w-full md:basis-1/4">
              <div
                style={{
                  backgroundColor: counter.bgColor,
                  borderColor: counter.borderColor,
                }}
                className="rounded-xl p-6 w-full text-center border"
              >
                <h4 className="text-[#1F1F1F] text-4xl md:text-4xl font-bold mb-2">
                  {counter.value}
                </h4>
                <p className="text-base md:text-base font-medium md:font-normal">
                  {counter.label}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Counter;
