import React from "react";

const MobileRightImageSection = ({
  rightImgSrc,
  title,
  description,
  bgColor,
}) => {
  return (
    <section className="py-8 md:py-14">
      <div className="mx-auto container ">
        <div className="flex flex-col gap-4">
          <div className={`w-full md:w-3/5 flex rounded-2xl ${bgColor}`}>
            <img
              src={rightImgSrc}
              alt="Leads"
              className="object-cover w-full"
            />
          </div>
          <div className="w-4/5 md:w-2/5 flex items-center justify-center relative left-10 ">
            <div className="bg-white border-custom rounded-xl p-6 md:p-12 w-full text-left shadow-md border shadow-custom ">
              <h4 className="text-[#1F1F1F] text-3xl font-medium mb-3">
                {title}
              </h4>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileRightImageSection;
