// src/components/Layout.js
import React from "react";
import RetailChainHeader from "./RetailChainHeader";
import RetailChainFooter from "./RetailChainFooter.js";
import { Outlet } from "react-router-dom";

const RetailChainLayout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <RetailChainHeader />
      <main className="flex-grow">
        <Outlet />
      </main>
      <RetailChainFooter />
    </div>
  );
};

export default RetailChainLayout;
