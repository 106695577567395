import React from "react";
import Benifits from "../../images/BenifitsImg.svg";

const Why = () => {
  return (
    <>
      <section className="py-10 mt-16 lg:mt-0 bg-[#FEE860] whychooseDesktop ">
        <div className="flex justify-center relative  m-auto container">
          <img className="" src={Benifits} alt="" />
          {/* Heading */}
          <div className="absolute w-[35%] left-4 top-[13%] text-[64px] font-[Poppins] font-semibold text-black">
            BENEFITS YOU CAN GET
          </div>
          {/* Content Right 1 */}
          <div className="absolute w-[35%] right-4 top-[13%] text-[16px] font-[Poppins] font-normal text-paragraphText">
            <span className="text-[18px] font-semibold text-paragraphText">
              Lorem Ipsum is simply
            </span>{" "}
            dummy text of the printing and typesetting industry. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry.
          </div>
          {/* Content Right 2 */}
          <div className="absolute w-[31%] right-4 top-[32%] text-[16px] font-[Poppins] font-normal text-paragraphText">
            <span className="text-[18px] font-semibold text-paragraphText">
              Lorem Ipsum is simply
            </span>{" "}
            dummy text of the printing and typesetting industry. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry.
          </div>
          {/* Content Right 3 */}
          <div className="absolute w-[34%] right-4 top-[52%] text-[16px] font-[Poppins] font-normal text-paragraphText">
            <span className="text-[18px] font-semibold text-paragraphText">
              Lorem Ipsum is simply
            </span>{" "}
            dummy text of the printing and typesetting industry. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry.
          </div>
          {/* Content Right 4 */}
          <div className="absolute w-[31%] right-4 top-[71%] text-[16px] font-[Poppins] font-normal text-paragraphText">
            <span className="text-[18px] font-semibold text-paragraphText">
              Lorem Ipsum is simply
            </span>{" "}
            dummy text of the printing and typesetting industry. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry.
          </div>
          {/* Content Left 2 */}
          <div className="absolute w-[31%] left-4 top-[61%] text-right text-[16px] font-[Poppins] font-normal text-paragraphText">
            <span className="text-[18px] font-semibold text-paragraphText">
              Lorem Ipsum is simply
            </span>{" "}
            dummy text of the printing and typesetting industry. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry.
          </div>
        </div>
      </section>

      {/* Mobile View */}
      <section className="px-10 py-10 bg-[#FEE860] whychooseMobile">
        <div className="text-[42px] md:text-[32px] font-semibold font-[Poppins] text-black leading-[48px] mb-8">
          BENIFITS YOU CAN GET
        </div>
        <div className="text-[16px] font-[Poppins] py-4 font-normal text-paragraphText">
          <span className="text-[18px] font-semibold text-paragraphText">
            Lorem Ipsum is simply
          </span>
          dummy text of the printing and typesetting industry. Lorem Ipsum is
          simply dummy text of the printing and typesetting industry.
        </div>

        <div className="text-[16px] font-[Poppins] py-4 font-normal text-paragraphText">
          <span className="text-[18px] font-semibold text-paragraphText">
            Lorem Ipsum is simply
          </span>
          dummy text of the printing and typesetting industry. Lorem Ipsum is
          simply dummy text of the printing and typesetting industry.
        </div>

        <div className="text-[16px] font-[Poppins] py-4 font-normal text-paragraphText">
          <span className="text-[18px] font-semibold text-paragraphText">
            Lorem Ipsum is simply
          </span>
          dummy text of the printing and typesetting industry. Lorem Ipsum is
          simply dummy text of the printing and typesetting industry.
        </div>

        <div className="text-[16px] font-[Poppins] py-4 font-normal text-paragraphText">
          <span className="text-[18px] font-semibold text-paragraphText">
            Lorem Ipsum is simply
          </span>
          dummy text of the printing and typesetting industry. Lorem Ipsum is
          simply dummy text of the printing and typesetting industry.
        </div>

        <div className="text-[16px] font-[Poppins] py-4 font-normal text-paragraphText">
          <span className="text-[18px] font-semibold text-paragraphText">
            Lorem Ipsum is simply
          </span>
          dummy text of the printing and typesetting industry. Lorem Ipsum is
          simply dummy text of the printing and typesetting industry.
        </div>
      </section>
    </>
  );
};

export default Why;
