import React, { useState } from "react";

const FAQmobile = ({ faqData, backgroundColor, font,text }) => {
  return (
    <section className="py-10 px-4 bg-Yellow faq-section" style={{backgroundColor,fontFamily:font, color:text}}>
      <div className="container mx-auto flex flex-col lg:flex-row lg:justify-between lg:px-12">
        <p className="text-3xl lg:text-4xl font-medium uppercase mb-6 lg:mb-0">
          Frequently Asked <br />
          Questions.
        </p>
        <div className="w-full lg:w-1/2 faq-content-container">
          <div className="faq-content">
            {faqData.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQItem = ({ question, answer, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`faq-item font-urbanist mb-4 border-b last:border-none transition-all duration-300 ${isOpen ? "border-gray-300" : ""}`}
      style={{ width: "100%", transition: "none" }}
    >
      <div
        className="faq-question flex justify-evenly w-[90%] items-center text-base cursor-pointer py-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl lg:text-xl">{question}</span>
        <span className="faq-toggle sm:text-base text-lg"style={{ color: text }}>{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && (
        <div className="faq-answer py-2 text-sm lg:text-base transition-all duration-300 ease-in-out">
          {answer}
        </div>
      )}
    </div>
  );
};

export default FAQmobile;
