import React from 'react'
import { Link } from 'react-router-dom'
import RightArrow from "../../images/trial-arrow-right.svg"
import vivid1 from "../../images/vivid1.svg";
import vivid2 from "../../images/vivid2.svg";
import vivid3 from "../../images/vivid3.svg";
import vivid4 from "../../images/vivid4.svg";
import vivid5 from "../../images/vivid5.svg";
import vivid6 from "../../images/vivid6.svg"
import bg from "../../images/posbg.svg"

function Trial({ backgroundColor, title, items, subtitle, linkUrl }) {
  const slide = [
    {
      images: [vivid1, vivid2, vivid3, vivid4, vivid5, vivid6],
    }
  ];

  return (
    <section
      className="flex md:flex-row flex-col w-full relative min-h-screen -mt-20"
      style={{
        backgroundColor,
        backgroundImage: `url(${slide[0].images[0]}), url(${slide[0].images[1]}), url(${slide[0].images[2]}), url(${slide[0].images[3]}), url(${slide[0].images[4]}), url(${slide[0].images[5]})`,
        backgroundPosition: 'center center, right bottom, left top, right top, left bottom , right bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="md:w-[60%] w-full flex flex-col mt-[40%] md:mt-0 md:items-start items-center justify-center p-4 md:p-8">
        {/* Text Section */}
        <div className="md:text-left text-center font-onest">
          <h4 className="text-white mb-4 text-[30px] md:text-[50px] font-bold uppercase leading-[44px] md:leading-[80px]">
            {title}
          </h4>
          <p className="xl:pb-4 text-base text-white">{subtitle}</p>
        </div>

        <div className="mt-4">
          {items.map((item) => (
            <React.Fragment key={item.id}>
              {item.buttons.map((button, idx) => (
                <Link to={linkUrl} key={idx} className="flex items-center">
                  <button className="my-2 px-6 py-3 font-onest bg-white text-black rounded-full uppercase text-lg font-medium">
                    {button.text}
                  </button>
                  <button className="ml-1 bg-white p-4 px-5 rounded-full">
                    <img src={RightArrow} alt="arrow icon" />
                  </button>
                </Link>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="md:w-[40%] w-full md:py-0 py-8 flex items-center justify-center">
        <img src={bg} alt="Background Graphic" className="w-full h-auto" />
      </div>
    </section>
  );
}

export default Trial;
