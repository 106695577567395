import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

function Payrollhero({
  title,
  payrollimg, // Use the payroll image passed as a prop
  cardcontent,
  linkUrl,
  buttonText,
  higlighttitle,
  animationData,
  bgImage,
  onClick
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="pb-6 md:pb-12 bg-white mx-auto overflow-x-hidden">
      <div className="mx-4 md:ml-8 z-10">
        <div className="flex flex-col md:flex-row gap-12 md:gap-[6rem] lg:gap-52  items-center">
          <div className="w-full mt-[30px] md:w-1/2 text-center md:text-left">
            <h1 className="text-black text-4xl md:text-5xl lg:text-7xl font-semibold mb-4 font-outfit">
              <span className="text-GreenText uppercase font-semibold text-4xl">
                {higlighttitle}&nbsp;
              </span>
              {title}
            </h1>
            <div className="mb-4">
              <p className="text-paragraphText md:text-lg lg:text-2xl font-urbanist font-semibold">
                {cardcontent}
              </p>
            </div>
            {onClick ? (
            // If onClick is provided, use a button with the onClick handler
            <button
              onClick={onClick}
              className="font-poppins text-2xl md:text-lg px-4 py-2 bg-GreenText text-headerText hover:bg-white border-GreenText border-[2px] rounded-lg mb-2 mt-0 md:mt-2 uppercase font-500"
            >
              {buttonText}
            </button>
          ) : (
            // Otherwise, fall back to the Link component
            <Link to={linkUrl}>
              <button className="font-poppins text-2xl md:text-lg px-4 py-2 bg-GreenText text-white hover:bg-white hover:text-GreenText hover:border-GreenText border-2 border-transparent rounded-lg mb-2 mt-0 md:mt-2 uppercase font-500 transition-all duration-300">
                {buttonText}
              </button>
            </Link>
          )}
          </div>

          <div>
            {/* Replace bannerimg with payrollimg */}
            <img
              src={payrollimg} // Use payrollimg prop here
              alt="Payroll Image"
              className="mx-auto sm:mx-0"
              style={{ animation: "fadeIn 2s ease-in-out" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Payrollhero;
