import React from "react";

const RightimageSection = ({ rightImgSrc, title, description, bgColor }) => {
  return (
    <section className="py-8 md:py-14">
      <div className="mx-auto container m-auto">
        <div className="block md:flex md:flex-row gap-4">
          <div className="w-4/5 md:w-2/5 flex items-center justify-center relative right-10 order-2 md:order-1">
            <div className="bg-white border-custom rounded-xl p-6 md:p-12 w-full text-left shadow-md border shadow-custom ">
              <h4 className="text-[#1F1F1F] text-3xl font-medium mb-3">
                {title}
              </h4>
              <p>{description}</p>
            </div>
          </div>
          <div
            className={`w-full md:w-3/5 flex rounded-2xl order-1 md:order-2 ${bgColor}`}
          >
            <img
              src={rightImgSrc}
              alt="Leads"
              className="object-cover w-9/12 right-img-padding"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightimageSection;
